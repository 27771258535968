/* eslint-disable max-len */
import React, {useState, useEffect} from 'react'
import {
  Modal,
  Icon,
  Card,
  Input,
  Button,
  Table,
  TableEntry,
} from '@lazarusai/forms-ui-components'
import {connect} from 'react-redux'
import {getTrainingSets} from '../actions/getTrainingSets.js'
import {storePayload} from '../actions/storePayload'
import '../styles/Training.css'
import FullLoadingScreen from './FullLoadingScreen.jsx'
import endpoints from '../endpoints.json'

function Training(props) {
  const [zipFile, setZipFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [modelInfo, setModelInfo] = useState({})
  // const [trainingSets, setTrainingSets] = useState([])
  const [isGettingTrainingSets, setIsGettingTrainingSets] = useState(false)

  const hasPermission =
    // false
    props.orgDetails &&
    props.orgDetails.permissions &&
    props.orgDetails.permissions.canUploadTrainingData

  useEffect(() => {
    if (hasPermission && !props.trainingSets) {
      setIsGettingTrainingSets(true)
      props.getTrainingSets(props.database, props.orgId)
    }
  }, [isGettingTrainingSets, props.trainingSets, props.orgDetails])

  function onChangeFile(e) {
    const fullPath = e.target.value
    if (fullPath) {
      const startIndex =
        fullPath.indexOf('\\') >= 0 ?
          fullPath.lastIndexOf('\\') :
          fullPath.lastIndexOf('/')
      let filename = fullPath.substring(startIndex)
      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1)
      }
      setFileName(filename)
    }
    setZipFile(e.target)
  }

  function onChangeModelInfo(e) {
    setModelInfo({
      ...modelInfo,
      [e.target.name]: e.target.value,
    })
  }

  async function uploadTrainingSet() {
    // const url = process.env.REACT_APP_URL + 'upload/trainingset'
    const url = process.env.REACT_APP_URL + endpoints.uploadTraining
    // const data = {
    //   org_id: props.orgId,
    //   user_id: props.user.uid,
    //   set_name: modelInfo.set_name,
    //   model_type: modelInfo.model_type,
    //   description: modelInfo.description,
    // }

    // const fileType = zipFile.type
    // const blob = new Blob([zipFile], {type: fileType})

    /* didn't work:
       const formdata = new FormData(data)
    */

    const formdata = new FormData()
    formdata.append('file', zipFile.files[0], fileName)
    formdata.append('org_id', props.orgId)
    formdata.append('user_id', props.user.uid)
    formdata.append('set_name', modelInfo.set_name)
    formdata.append('model_type', modelInfo.model_type)
    formdata.append('description', modelInfo.description)

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'manual',
    }

    const response = await fetch(url, requestOptions)
    // parses JSON response into native JavaScript objects
    return response.json()
  }

  function onClickSendTrainingData(e) {
    e.preventDefault()
    const isValid =
      props.orgId &&
      props.user.uid &&
      modelInfo.set_name &&
      modelInfo.model_type &&
      zipFile.files[0]

    if (!isValid) {
      props.storePayload({
        userMessage: 'Please make sure you fill out the entire form',
        notificationType: 3,
        notificationIcon: 'warning',
      })
      return 'error'
    }
    props.storePayload({isLoading: true})
    uploadTrainingSet()
        .then((data) => {
        // JSON data parsed by `data.json()` call
          if (data.status === 'success') {
            props.storePayload({
              userMessage: 'Training data uploaded successfully',
              notificationType: 2,
              notificationIcon: 'check',
            })
          } else {
            props.storePayload({
              userMessage: 'Training data upload failed',
              notificationType: 3,
              notificationIcon: 'warning',
            })
          }
        })
        .catch((data) => {
          props.storePayload({
            userMessage: 'Training data upload failed',
            notificationType: 3,
            notificationIcon: 'warning',
          })
        })
        .finally(() => {
          props.storePayload({isLoading: false})
        })
  }

  const entries =
    props.trainingSets &&
    props.trainingSets.map((model, i) => {
      return (
        <TableEntry
          key={i}
          content={[
            <div key={i + 0} className='text-bright-blue'>
              {model.name}
            </div>,
            <span key={i + 1}>{model.fileName}</span>,
            <div className='text-light flex align-center' key={i + 2}>
              <Icon icon='book-outline' />
              {model.modelType}
            </div>,
            [model.status, model.status],
          ]}
        />
      )
    })

  return (
    <>
      {hasPermission &&
      props.billing &&
      props.billing['contract'] &&
      props.billing['contract']['Active'] ? (
        <div id='training-section' className='gap-group-medium show-scrollbar'>
          <form onChange={onChangeModelInfo} className='gap-group-medium'>
            <label id='training-input-container' htmlFor='model-training-input' className='cursor-pointer'>
              <Card
                theme={props.theme}
                className={
                  zipFile ?
                    'training-file-input background-salem' :
                    'training-file-input background-metallic-blue'
                }
                title={'Get Started'}
              >
                <input
                  required
                  type='file'
                  accept='.zip,.rar,.7zip'
                  id='model-training-input'
                  className='file-input invisible'
                  onChange={onChangeFile}
                  name='set_file'
                ></input>
                <input
                  required
                  name='file_name'
                  value={fileName}
                  onChange={onChangeModelInfo}
                  className='invisible'
                />
                <h3 className='align-baseline flex text-light-blue font-weight-regular align-center'>
                  <div
                    className={`toggle-container ${zipFile ? 'toggled' : ''}`}
                  >
                    <Icon icon='file-outline'></Icon>
                    <Icon icon='file-add-outline'></Icon>
                  </div>
                  {fileName ? fileName : 'Upload ZIP File'}
                </h3>
                <div className='flex text-bright-blue text-medium align-center line-height-1'>
                  <div
                    className={`toggle-container ${zipFile ? 'toggled' : ''}`}
                  >
                    <Icon icon='checkmark-outline'></Icon>
                    <Icon icon='plus-outline'></Icon>
                  </div>
                  <p>{zipFile ? 'Selected' : 'Add a File'}</p>
                </div>
              </Card>
            </label>
            {zipFile && (
              <Card
                theme={props.theme}
                className='model-info background-metallic-blue animate-in-expand'
                title='Model Information'
              >
                <Input
                  required
                  name='set_name'
                  placeholder='Type a model name'
                  onChange={onChangeModelInfo}
                  theme={props.theme}
                  label='Model name'
                  value={modelInfo.set_name}
                />
                <Input
                  type={3}
                  required
                  id='type'
                  name='model_type'
                  onChange={onChangeModelInfo}
                  options={['classification', 'form extraction']}
                  selected={null}
                  theme={props.theme}
                  placeholder='Model type'
                  label='Select a type'
                  value={modelInfo.model_type}
                >
                  <option value='classification'>Classification</option>
                  <option value='form extraction'>Form Extraction</option>
                </Input>

                <Input
                  placeholder='Describe your model'
                  name='description'
                  className='text-area'
                  value={modelInfo.description}
                  onChange={onChangeModelInfo}
                  type={2}
                  theme={props.theme}
                  label='Description'
                />
                <Button
                  type={7}
                  onClick={onClickSendTrainingData}
                  text='Send for Training'
                  theme={props.theme}
                />
              </Card>
            )}
          </form>
          <Card
            title='Model Training'
            theme={props.theme}
            className='model-training background-gray-20'
          >
            <div className='flex'>
              <Icon icon='info-outline' />
              <div className='gap-group  show-scrollbar'>
                <div>
                  <h4>Classification models:</h4>
                  <p>
                    Upload a ZIP file that contains folders named for each class
                    desired. Each folder should contain the relevant documents
                    of that class.
                  </p>
                </div>
                <div>
                  <h4>Accepted document formats:</h4>
                  <i>PDF, JPEG, PNG, TIFF</i>
                </div>
                <div>
                  <h4>Extraction models:</h4>
                  Upload a ZIP file that contains:
                  <ul>
                    <li> a training folder</li>
                    <li>
                      {' '}
                      a JSON folder with the desired fields in COCO format.
                    </li>
                    <li>a sample file</li>
                  </ul>
                </div>
              </div>
            </div>
          </Card>

          {props.trainingSets && (
            <Table
              title='Model training log'
              showingCount={props.trainingSets.length}
              total={props.trainingSets.length}
              columnTitles={['Model name', 'Data file', 'Model type', 'Status']}
              theme={props.theme}
              entries={entries}
            />
          )}
        </div>
      ) : props.isLoading ? (
        <FullLoadingScreen theme={props.theme}/>
      ) : (
        <div id='training'>
          <Modal
            theme={props.theme}
            isVisible={true}
            content={
              <div className='flex text-gold align-center'>
                <Icon icon='info-outline' />
                <span>
                  You currently don&apos;t have access to this feature.
                </span>
              </div>
            }
            showClose={false}
            showConfirm={false}
          />
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  trainingSets: state.userReducer.trainingSets,
  orgId: state.userReducer.orgId,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  billing: state.userReducer.billing,
  theme: state.userReducer.theme,
})

export default connect(mapStateToProps, {getTrainingSets, storePayload})(Training)
