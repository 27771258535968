const text = (orgId, authKey) =>
  `OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, 
  "{\"base64\":\"base64_string\",\"fileId\":\"abc\",\"metadata\":{},\"webhook\":\"google.com\"}");
Request request = new Request.Builder()
  .url("https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/forms/generic")
  .method("POST", body)
  .addHeader("orgId", ${orgId})
  .addHeader("authKey", ${authKey})
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();`

export default text
