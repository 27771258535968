import React, {useState, useEffect} from 'react'
import {Table, TableEntry} from '@lazarusai/forms-ui-components'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload.js'
import {getLogData} from '../actions/getLogData.js'
import {logAnalyticsEvent} from '../actions/logAnalyticsEvent'
import '../styles/Logs.css'
import '../styles/Table.css'
import Helpers from '../Helpers.js'

function ModelUsage(props) {
  const [entries, setEntries] = useState([])
  const {models} = props

  useEffect(() => {
    if (models?.[props.orgId] && props.models?.[props.orgId]?.length) {
      const newEntries = props.models?.[props.orgId].map((model, i) => {
        return (
          <TableEntry
            key={`${model.model_id}-${i}-${model?.timestamp || Date.now()}`}
            content={[
              model.model_id,
              model?.model_name?.length ? model?.model_name : props.modelInformation?.[model.model_id]?.public?.metadata?.name || 'N/A',
              Helpers.timeStampToDate(model.timestamp * 1000),
              model.successes,
              model.failures,
              model.pages,
              model.questions,
            ]}
          />
        )
      })
      setEntries(newEntries)
    }
  }, [models])

  if (models?.[props.orgId] && Object.keys(props.models?.[props.orgId]).length > 0) {
    return (
      <Table
        title='Models'
        columnTitles={['ID', 'Name', 'Last Use', 'Successes', 'Failures', 'Total Pages', 'Total Questions']}
        entries={entries}
        theme={props.theme}
        showTotal={true}
        showCount={true}
        className='table-container'
      />
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => ({
  orgId: state.userReducer.orgId,
  theme: state.userReducer.theme,
  modelInformation: state.userReducer.modelInformation,
})

export default connect(mapStateToProps, {
  getLogData,
  storePayload,
  logAnalyticsEvent,
})(ModelUsage)
