import types from '../types'

export const getVersionDetails = (database) => (dispatch) => {
  const dbRef = database.ref(`changeNotes`)
  let liveVersion
  dbRef
      .child('versionNotes')
      .orderByChild('releaseDate')
      .limitToLast(1)
      .get()
      .then((snapshot) => {
        const changeNotesVal = snapshot.val()
        if (changeNotesVal) {
          const versions = Object.keys(changeNotesVal)
          liveVersion = changeNotesVal[versions[0]]
        }
        dbRef
            .child('termsDate')
            .get()
            .then((snapshot) => {
              const date = snapshot.val() || 0
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  changeLogNotes: liveVersion,
                  termsDate: date,
                },
              })
            })
            .catch((errorObject) => console.log(errorObject))
      })
      .catch((errorObject) => console.log(errorObject))
}
