import types from '../types'
import 'firebase/compat/auth'
import 'firebase/compat/database'

export const setDefaultLanguage = (database, language, defaults, orgId) => (dispatch) => {
  const newDefaults = {
    ...defaults,
    'defaultLanguage': language,
  }
  const dbRef = database.ref(`organizations/${orgId}/orgDetails/defaults`)
  return dbRef
      .set(newDefaults)
      .then(() => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Default language successfully saved',
            notificationIcon: 'check',
            notificationType: 2,
          },
        })
        return true
      })
      .catch((err) => {
        console.log('error', err)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Error setting language default.',
            notificationIcon: 'warning',
            notificationType: 3,
          },
        })
        return false
      })
}
