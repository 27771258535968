import {getAuth, getMultiFactorResolver, TotpMultiFactorGenerator} from 'firebase/auth'
import types from '../types'

// Action that handles MFA sign-in

// This must return a function for thunk to handle it
export const enterMFACode = (otp, error) => (dispatch) => {
  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      isLoading: true,
    },
  })
  const auth = getAuth()
  const resolver = getMultiFactorResolver(auth, error)
  // hints[0] is the index for TOTP as method of sign-in
  const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(resolver.hints[0].uid, otp)
  // Finalize sign-in
  return resolver.resolveSignIn(multiFactorAssertion)
      .catch((error) => {
        if (error.code === 'auth/invalid-verification-code') {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: 'Invalid code - please try again.',
              notificationType: 3,
              notificationIcon: 'warning',
              isLoading: false,
            },
          })
        }
      })
}
