import React from 'react'

import {Header as HeaderComp} from '@lazarusai/forms-ui-components'
import {getVersionDetails} from '../actions/getVersionDetails'

import {connect} from 'react-redux'

function Header(props) {
  return <HeaderComp {...props} />
}

const mapStateToProps = (state, ownProps) => ({
  changeLogNotes: state.userReducer.changeLogNotes,
  theme: state.userReducer.theme,
})

export default connect(mapStateToProps, {getVersionDetails})(Header)
