import Helpers from '../Helpers'

export const validateLicenseKey = (licenseKey) => {
  const data = {
    'meta': {
      'key': licenseKey,
    },
  }
  return Helpers.fetchPostJson(process.env.REACT_APP_LICENSE_URL, data, {})
      .then((res) => {
        if (res.meta.valid) {
          return true
        } else {
          return false
        }
      })
      .catch((err) => {
        console.log('err: ', err)
      })
}
