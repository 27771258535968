import types from '../types'
import 'firebase/compat/analytics'

export const removeAnalytics = () => (dispatch) => {
  const firebaseAppPayload = {
    analytics: null,
  }

  dispatch({
    type: types.CREATE_FIREBASE_APP,
    payload: firebaseAppPayload,
  })
}
