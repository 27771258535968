import types from '../types'
import 'firebase/compat/database'

export const getCustomRoles = (database, orgId) => async (dispatch) => {
  const dbRef = database.ref(`organizations/${orgId}/customRoles`)
  await dbRef.on(
      'value',
      (snapshot) => {
        const val = snapshot.val()
        const customRoles = val || {}
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            customRoles,
          },
        })
      },
      (errorObject) => console.log(errorObject),
  )
}
