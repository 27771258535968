import React from 'react'
import Highlight from 'react-highlight'
import '../styles/Code.css'
import {Card} from '@lazarusai/forms-ui-components'

const Code = ({language, code, theme}, props) => (
  <Card className='code no-scrollbar' theme={theme}>
    <Highlight className={`${language} code-block no-scrollbar`} {...props}>
      {code}
    </Highlight>
  </Card>
)

export default Code
