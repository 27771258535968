/* eslint-disable max-len */
const text = (orgId, authKey) =>
  `
OkHttpClient client = new OkHttpClient().newBuilder()
 .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\"base64\":\"base64_string\",\"question\":\"question_HERE"\",\"fileId\":\"file_id_HERE\",\"metadata\":{},\"webhook\":\"webhook_HERE\"}");
Request request = new Request.Builder()
 .url("https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/rikai/custom/your_custom_model_id_HERE")
 .method("POST", body)
 .addHeader("orgId", ${orgId})
 .addHeader("authKey", ${authKey})
 .addHeader("Content-Type", "application/json")
 .build();
Response response = client.newCall(request).execute();
 `

export default text
