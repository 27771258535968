import types from '../types'
import 'firebase/compat/database'

export const getBilling = (database, orgId) => async (dispatch) => {
  const dbRef = database.ref(`organizations/${orgId}/billing`)

  await dbRef.on(
      'value',
      (snapshot) => {
        const val = snapshot.val()
        const billing = val
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            billing,
          },
        })
      },
      (errorObject) => console.log(errorObject),
  )
}
