const codingLanguageList = [
  'Ruby',
  'Python',
  'Javascript',
  'Java',
  'Go',
  'Swift',
  'PHP',
  'C#',
  'C',
  'Curl',
]

export default codingLanguageList
