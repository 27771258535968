import types from '../types'
import Helpers from '../Helpers.js'
import endpoints from '.././endpoints.json'

export const getCustomerPortal =
  (orgId, userId, userEmail, setIsSettingsLoading, anchorElem) =>
    (dispatch, getState) => {
      const oldCustomerPortal = getState().userReducer.customerPortal
      if (!oldCustomerPortal) {
        // const url = process.env.REACT_APP_URL + 'create-customer-portal'
        const url = process.env.REACT_APP_URL + endpoints.createCustomer
        const data = {
          org_id: orgId,
          user_id: userId,
          user_email: userEmail,
        }
        Helpers.fetchPostJson(url, data)
            .then((res) => {
              const customerPortal = res['customer-portal']
              if (anchorElem) {
                anchorElem.setAttribute('href', customerPortal)
                anchorElem.click()
              }
              if (customerPortal) {
                dispatch({
                  type: types.STORE_PAYLOAD,
                  payload: {
                    customerPortal: customerPortal,
                    isLoading: false,
                  },
                })
              } else {
                dispatch({
                  type: types.STORE_PAYLOAD,
                  payload: {
                    userMessage: 'Error pulling customer billing link',
                    notificationIcon: 'warning',
                    notificationType: 3,
                    isLoading: false,
                  },
                })
              }
            })
            .catch((res) => {
              console.log('error res', res)
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  userMessage: 'Error pulling customer billing link',
                  notificationIcon: 'warning',
                  notificationType: 3,
                  isLoading: false,
                },
              })
            })
            .finally(() => setIsSettingsLoading(false))
      }
    }
