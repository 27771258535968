import types from '../types'
import Helpers from '../Helpers.js'
import endpoints from '.././endpoints.json'


export const getSubOrganizationDetails = (orgId, authKey, userId, subOrgId, inBackground=false) => (dispatch) => {
  const url = process.env.REACT_APP_URL + endpoints.subOrgs
  const headers = {
    'ORGID': orgId,
    'AUTHKEY': authKey,
    'USERID': userId,
    'suborgid': subOrgId,
  }

  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      isLoading: !inBackground,
    },
  })

  Helpers.fetchGet(url, headers)
      .then((res) => {
        if (res?.status === 'SUCCESS') {
          delete res.status
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              subOrganizationDetails: res,
            },
          })
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: `Error getting suborganization information${res.message ? `: ${res.message}`: '.'}`,
              notificationIcon: 'warning',
              notificationType: 2,
            },
          })
        }
      })
      .catch((res) => {
        console.log('error res', res)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Error getting suborganization information.',
            notificationIcon: 'warning',
            notificationType: 2,
          },
        })
      })
      .finally(() => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            isLoading: false,
          },
        })
      })
}
