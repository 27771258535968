import types from '../types'

export const getUsers = (database, orgId) => async (dispatch) => {
  const dbRef = database
      .ref(`organizations/${orgId}/userRoster`)
      .orderByChild('timestampCreated')
  await dbRef.on('value', (snapshot) => {
    const val = snapshot.val()
    const usersObj = val
    const users = Object.keys(usersObj).map((id) => {
      return {...usersObj[id], id}
    })
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        users,
        usersObj,
      },
    })
  }, (errorObject) => console.log(errorObject))
}

