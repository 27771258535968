import Helpers from '../Helpers'
import types from '../types'

export const getOrgModelMetrics = (orgId) => (dispatch, getState) => {
  const authKey = getState().userReducer.authKey
  const url = process.env.REACT_APP_METRICS_URL + 'api/query/models?filter_demo_init=true'
  return Helpers.fetchGet(url, {'orgId': orgId, 'authKey': authKey})
      .then((resp) => {
        if (resp?.status === 'SUCCESS') {
          const orgModelMetrics = getState().userReducer.orgModelMetrics || {}
          const models = resp.models.filter((model) => model?.model_id?.length)
          orgModelMetrics[orgId] = models
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              orgModelMetrics,
            },
          })
          return true
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: 'Error pulling organization model metrics.',
              notificationIcon: 'warning',
              notificationType: 2,
            },
          })
          return false
        }
      }).catch((error) => {
        console.log(error)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Error pulling organization model metrics.',
            notificationIcon: 'warning',
            notificationType: 2,
          },
        })
        return false
      })
}
