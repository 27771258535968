const text = (orgId, authKey) =>
  `
 import requests
 import json
 
 url = "https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/rikai/custom/your_custom_model_id_HERE"
 
 payload = json.dumps({
   "inputUrl": "inputUrl_HERE",
   "question": "question_HERE",
   "fileId": "fileId_HERE | Optional",
   "metadata": {"metadata_HERE | Optional"},
   "webhook": "webhook_HERE | Optional"
 })
 headers = {
   'orgId': ${orgId},
   'authKey': ${authKey},
   'Content-Type': 'application/json'
 }
 
 response = requests.request("POST", url, headers=headers, data=payload)
 
 print(response.text)
 `

export default text
