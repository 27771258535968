const text = (orgId, authKey) =>
  `
var client = new RestClient(endpoint);
var request = new RestRequest("/forms/identification", Method.Post);
request.Timeout = -1;
request.AddHeader("orgId", ${orgId});
request.AddHeader("authKey", ${authKey});
var body = @"{""base64"":""base64_string"",""fileId"":""abc"",""metadata"":{},""webhook"":""google.com""}";
request.AddParameter("application/json", body,  ParameterType.RequestBody);
RestResponse response = await client.ExecutePostAsync(request);
Console.WriteLine(response.Content);
  `

export default text
