import React from 'react'
import Nav from './Nav'
import Header from './Header'
import {getVersionDetails} from '../actions/getVersionDetails'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload'
import '../styles/Template.css'

function Template({component = () => <></>}, props) {
  const noHeaderPages = ['rikai', 'demo']
  const includesCheck = (sub) => window.location.href.includes(sub)
  const Component = component

  return (
    <div className='template'>
      <Nav />
      <main>
        {!noHeaderPages.some(includesCheck) && <Header />}
        <div
          className={`dynamic-content${
            noHeaderPages.some(includesCheck) ? ' no-top-margin' : ''
          }`}
        >
          {' '}
          {component && <Component {...props} />}
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  changeLogNotes: state.userReducer.changeLogNotes,
  theme: state.userReducer.theme,
  isMenu: state.userReducer.isMenu,
})

export default connect(mapStateToProps, {
  storePayload,
  getVersionDetails,
})(Template)
