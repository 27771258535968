import types from '../types'

export const getSubOrgs = (database, orgId) => (dispatch) => {
  const dbRef = database.ref(`organizations/${orgId}/subOrgs`)
  dbRef
      .get()
      .then((snapshot) => {
        const val = snapshot.val()
        const subOrgs = val
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            subOrgs,
          },
        })
      })
      .catch((err) => console.log('error', err))
}
