import types from '../types'

export const getOrgActionLogs = (database, orgId) => (dispatch) => {
  const dbRef = database
      .ref(`organizations/${orgId}/orgActionLogs`)
      .orderByChild('timestamp')
  dbRef.on('value', (snapshot) => {
    const orgActionLogsObj = snapshot.val()
    /* Order by child not working?
       Perhaps because the dictionary sorts alphebitcally?
       It works on Logs though...
    */
    if (orgActionLogsObj) {
      const orgActionLogKeys =
      Object.keys(orgActionLogsObj)
          .sort((a, b) => {
            return orgActionLogsObj[b].timestamp - orgActionLogsObj[a].timestamp
          })
      const orgActionLogs =
      orgActionLogKeys.map((key) => orgActionLogsObj[key])
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          orgActionLogs,
        },
      })
    }
  }, (errorObject) => console.log(errorObject))
}
