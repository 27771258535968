import types from '../types'

export const getMFAStatus = (database, userId, orgId) => (dispatch) => {
  const userRef = database.ref(`organizations/${orgId}/userRoster/${userId}`)
  userRef.child('preferences').get()
      .then((snapshot) => {
        let isMFAEnabled
        if (snapshot.exists()) {
          isMFAEnabled = snapshot.val().requiresMFA
        } else {
          isMFAEnabled = false
        }
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            isMFAEnabled,
          },
        })
      })
      .catch((err) => console.log('error', err))
}
