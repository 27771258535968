const text = (orgId, authKey) =>
  `import Foundation
#if canImport(FoundationNetworking)
import FoundationNetworking
#endif

var semaphore = DispatchSemaphore (value: 0)

let parameters = "{\"inputUrl\":\"inputUrl_HERE\",\"fileId\":\"fileId_HERE | Optional\",\"metadata\":{\"metadata_HERE | Optional\"},\"webhook\":\"webhook_HERE | Optional\"}"
let postData = parameters.data(using: .utf8)

var request = URLRequest(url: URL(string: "https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/forms/ocr")!,timeoutInterval: Double.infinity)
request.addValue(${orgId}, forHTTPHeaderField: "orgId")
request.addValue(${authKey}, forHTTPHeaderField: "authKey")
request.addValue("application/json", forHTTPHeaderField: "Content-Type")

request.httpMethod = "POST"
request.httpBody = postData

let task = URLSession.shared.dataTask(with: request) { data, response, error in 
  guard let data = data else {
    print(String(describing: error))
    semaphore.signal()
    return
  }
  print(String(data: data, encoding: .utf8)!)
  semaphore.signal()
}

task.resume()
semaphore.wait()`

export default text
