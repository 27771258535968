const text = (orgId, authKey) =>
  `
var myHeaders = new Headers();
myHeaders.append("orgId", ${orgId});
myHeaders.append("authKey", ${authKey});
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "inputUrl": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  "fileId": "fileId_HERE | Optional",
  "metadata": {"metadata_HERE | Optional"},
  "webhook": "webhook_HERE | Optional"
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/forms/invoices", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
 `

export default text
