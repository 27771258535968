import types from '../types'

export const markUserTutorialized =
  (database, user) => (dispatch, getState) => {
    const dbUrl = `users/${user.uid}/status`
    const dbRef = database.ref(dbUrl)
    const userData = getState().userReducer.userData
    const oldStatus = userData && userData.status ? userData.status : {}
    const newStatus = {
      ...oldStatus,
      isTutorialized: true,
    }
    dbRef
        .update(newStatus)
        .then((res) => {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isFirstTimeUser: false,
              isPostTutorialTracking: true,
            },
          })
        })
        .catch((err) => {
          throw err
        })
  }
