import types from '../types.js'
import Helpers from '../Helpers.js'
import endpoints from '../endpoints.json'


export const updateSubOrgCreatePermission = (orgId, authKey, userId, subOrgId, canCreateSubOrg) => (dispatch) => {
  const url = process.env.REACT_APP_URL + endpoints.subOrgsPermission
  const headers = {
    'ORGID': orgId,
    'AUTHKEY': authKey,
    'USERID': userId,
    'suborgid': subOrgId,
  }

  return Helpers.fetchPutJson(url, headers, {'canCreate': canCreateSubOrg})
      .then((res) => {
        if (res?.status === 'SUCCESS') {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: `Successfully updated can create sub organization permission`,
              notificationIcon: 'check',
              notificationType: 2,
            },
          })
          return true
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: `Failed to updated can create sub organization permission`,
              notificationIcon: 'warning',
              notificationType: 2,
            },
          })
          return false
        }
      })
      .catch((res) => {
        console.log('error res', res)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Failed to updated can create sub organization permission',
            notificationIcon: 'warning',
            notificationType: 2,
          },
        })
        return false
      })
}
