/* eslint-disable max-len */
const text = (orgId, authKey) => {
  const orgIdFormatted = orgId.slice(1, orgId.length - 1)
  const authKeyFormatted = authKey.slice(1, authKey.length - 1)
  return `
 <?php

 $curl = curl_init();
 
 curl_setopt_array($curl, array(
   CURLOPT_URL => 'https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/rikai/custom/your_custom_model_id_HERE',
   CURLOPT_RETURNTRANSFER => true,
   CURLOPT_ENCODING => '',
   CURLOPT_MAXREDIRS => 10,
   CURLOPT_TIMEOUT => 0,
   CURLOPT_FOLLOWLOCATION => true,
   CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
   CURLOPT_CUSTOMREQUEST => 'POST',
   CURLOPT_POSTFIELDS =>'{"inputUrl": "inputUrl_HERE", "fileId": "fileId_HERE | Optional", "question": "question_HERE", "metadata": {"metadata_HERE | Optional"}, "webhook": "webhook_HERE | Optional"}',
   CURLOPT_HTTPHEADER => array(
     'orgId: ${orgIdFormatted}',
     'authKey: ${authKeyFormatted}',
     'Content-Type: application/json'
   ),
 ));
 
 $response = curl_exec($curl);
 
 curl_close($curl);
 echo $response;
 `
}

export default text
