import types from '../types.js'
import Helpers from '../Helpers.js'
import endpoints from '../endpoints.json'


export const resetSubOrganizationAuthKey = (orgId, authKey, userId, subOrgId) => (dispatch) => {
  const url = process.env.REACT_APP_URL + endpoints.subOrgsAuthentication
  const headers = {
    'ORGID': orgId,
    'AUTHKEY': authKey,
    'USERID': userId,
    'suborgid': subOrgId,
  }

  Helpers.fetchPostJson(url, {}, headers)
      .then((res) => {
        if (res?.status === 'SUCCESS') {
          delete res.status
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: `Successfully reset sub organization auth key.`,
              notificationIcon: 'check',
              notificationType: 2,
              subOrgAuthKey: res.authKey,
            },
          })
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: `Error resetting sub organization auth key.`,
              notificationIcon: 'warning',
              notificationType: 2,
              subOrgAuthKey: undefined,
            },
          })
        }
      })
      .catch((res) => {
        console.log('error res', res)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Error resetting sub organization auth key.',
            notificationIcon: 'warning',
            notificationType: 2,
            subOrgAuthKey: undefined,
          },
        })
      })
}
