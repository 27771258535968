import React from 'react'
import {connect} from 'react-redux'
import {Button} from '@lazarusai/forms-ui-components'
import '../styles/CookiesModal.css'

function CookiesModal(props) {
  return (
    <div
      className={`cookies-modal ${props.isShowing ? 'slideInCookiesClass' : 'slideOutCookiesClass'}`}
    >
      <div
        className='cookies-modal-text'
      >
        By selecting, &apos;Accept Cookies&apos;, you agree to the use of cookies to analyze and improve our services.
        You can learn more about how and why we use cookies in our <a href='https://www.lazarusforms.com/legal#terms-and-conditions' target='_blank' rel='noopener noreferrer'>Cookie Policy</a>.
      </div>
      <div
        className='buttons'
      >
        <div className='buttons'>
          <Button
            onClick={props.denyFunction}
            text='Reject Cookies'
            theme={props.theme}
            style={{marginBottom: 0}}
            type='5'
          />
          <Button
            onClick={props.acceptFunction}
            text='Accept Cookies'
            theme={props.theme}
            style={{marginBottom: 0}}
            type='3'
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  theme: state.userReducer.theme,
})

export default connect(mapStateToProps, {})(
    CookiesModal,
)
