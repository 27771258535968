/* eslint-disable max-len */
const text = (orgId, authKey) =>
  `
 import Foundation
 #if canImport(FoundationNetworking)
 import FoundationNetworking
 #endif
 
 var semaphore = DispatchSemaphore (value: 0)

  let parameters = [
    [
      "key": "file",
      "src": "file_HERE",
      "type": "file"
    ],
    [
      "key": "question",
      "value": "question_HERE",
      "type": "text"
    ]] as [[String: Any]]
 
 let boundary = "Boundary-\(UUID().uuidString)"
 var body = ""
 var error: Error? = nil
 for param in parameters {
   if param["disabled"] == nil {
     let paramName = param["key"]!
     body += "--\(boundary)\r\n"
     body += "Content-Disposition:form-data; name=\"\(paramName)\""
     if param["contentType"] != nil {
       body += "\r\nContent-Type: \(param["contentType"] as! String)"
     }
     let paramType = param["type"] as! String
     if paramType == "text" {
       let paramValue = param["value"] as! String
       body += "\r\n\r\n\(paramValue)\r\n"
     } else {
       let paramSrc = param["src"] as! String
       let fileData = try NSData(contentsOfFile:paramSrc, options:[]) as Data
       let fileContent = String(data: fileData, encoding: .utf8)!
       body += "; filename=\"\(paramSrc)\"\r\n"
         + "Content-Type: \"content-type header\"\r\n\r\n\(fileContent)\r\n"
     }
   }
 }
 body += "--\(boundary)--\r\n";
 let postData = body.data(using: .utf8)
 
 var request = URLRequest(url: URL(string: "https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/rikai/custom/your_custom_model_id_HERE")!,timeoutInterval: Double.infinity)
 request.addValue(${orgId}, forHTTPHeaderField: "orgId")
 request.addValue(${authKey}, forHTTPHeaderField: "authKey")
 request.addValue("multipart/form-data; boundary=\(boundary)", forHTTPHeaderField: "Content-Type")
 
 request.httpMethod = "POST"
 request.httpBody = postData
 
 let task = URLSession.shared.dataTask(with: request) { data, response, error in 
   guard let data = data else {
     print(String(describing: error))
     semaphore.signal()
     return
   }
   print(String(data: data, encoding: .utf8)!)
   semaphore.signal()
 }
 
 task.resume()
 semaphore.wait()

 `

export default text
