import React, {useState, useEffect} from 'react'
import Helpers from '../Helpers'
import {connect} from 'react-redux'
import 'firebase/compat/auth'
import {
  Card,
  Switch,
} from '@lazarusai/forms-ui-components'
import {setMFAStatus} from '../actions/setMFAStatus.js'
import {getMFAStatus} from '../actions/getMFAStatus.js'
import {storePayload} from '../actions/storePayload'
import {
  multiFactor,
  TotpMultiFactorGenerator,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  getMultiFactorResolver,
} from 'firebase/auth';
import 'firebase/compat/auth'

function MFA(props) {
  const [totpSecret, setTotpSecret] = useState()
  const [reauthCaller, setReauthCaller] = useState('')
  const [MFAResolver, setMFAResolver] = useState()
  const [isMFAChecked, setIsMFAChecked] = useState(false)

  useEffect(() => {
    setIsMFAChecked(props.isMFAEnabled ? true : false)
  }, [props.isMFAEnabled])

  useEffect(() => {
    if (props.database && props.user.uid && props.orgId) {
      props.getMFAStatus(props.database, props.user.uid, props.orgId)
    }
  }, [props.database, props.user.uid, props.orgId])


  function onMFASwitchChange() {
    if (props.isMFAEnabled) {
      unenrollMFA()
    } else {
      enrollMFA()
    }
  }

  async function enrollMFA() {
    const multiFactorSession = await multiFactor(props.user).getSession()
    TotpMultiFactorGenerator.generateSecret(multiFactorSession)
        .then((secret) => {
          props.storePayload({
            isMFAModal: true,
            isMFAModalShowing: true,
          })
          setTotpSecret(secret)
          props.storePayload({
            QRCodeUrl: secret.generateQrCodeUrl('', `Lazarus Forms - ${process.env.REACT_APP_ENVIRONMENT_NAME} (init. ${new Date().toJSON().slice(0, 10)})`),
          })
        })
        .catch((err) => {
          console.log('err', err)
          if (err.code == 'auth/requires-recent-login') {
            // re-authentication
            props.storePayload({
              isMFAModalShowing: false,
            })
            reauthenticate('enroll')
          } else if (err.code === 'auth/maximum-second-factor-count-exceeded') {
            // this case technically should not ever happen
            props.storePayload({
              notificationType: 3,
              userMessage: 'Already Enrolled in MFA',
            })
            props.storePayload({
              isMFAModalShowing: false,
            })
          } else {
            props.storePayload({
              notificationType: 3,
              userMessage: 'There was an unknown error. Please try again.',
              notificationIcon: 'warning',
            })
            console.log(err)
          }
        })
  }

  useEffect(() => {
    if (props.MFAOTPInput) {
      confirmEnrollMFA()
    }
  }, [props.MFAOTPInput])

  function confirmEnrollMFA() {
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
        totpSecret,
        props.MFAOTPInput,
    );
    multiFactor(props.user).enroll(multiFactorAssertion, 'app')
        .then(() => {
          props.storePayload({
            isMFAModalShowing: false,
          })
          props.setMFAStatus(props.database, props.user.uid, props.orgId, true)
          props.storePayload({
            isMFAModalShowing: false,
          })
          props.storePayload({
            notificationType: 3,
            userMessage: 'Your passcode has been successfully verified. Two-factor authentication is now enabled for your account.',
            notificationIcon: 'check',
          })
        })
        .then(() => {
          Helpers.notifyUser(getAuth().currentUser.email, 'mfa_enabled')
        })
        .catch((err) => {
          if (err.code === 'auth/invalid-verification-code') {
            console.log('err', err.code)
            props.storePayload({
              notificationType: 3,
              userMessage: 'Error: Incorrect one-time passcode',
            })
            setTimeout(() => props.storePayload({
              userMessage: null,
            }), 5000)
          } else {
            console.log(err)
            props.storePayload({
              notificationType: 3,
              userMessage: 'There was an unknown error. Please try again.',
              notificationIcon: 'warning',
            })
          }
        })
        .finally(() => {
          props.storePayload({
            mfaModalPage: 1,
          })
        })
  }

  function unenrollMFA() {
    const multiFactorInfo = multiFactor(props.user).enrolledFactors
    if (multiFactorInfo.length === 0) {
      // this case technically should be impossible
      props.storePayload({
        notificationType: 3,
        userMessage: 'Something went wrong: user not enrolled in MFA',
      })
    } else {
      // assume array multiFactorInfo only has one element since
      // currently we only allow one additional factor
      multiFactor(props.user).unenroll(multiFactorInfo[0])
          .then(() => {
            props.storePayload({
              notificationType: 3,
              userMessage: 'Two-factor authentication is now disabled for your account.',
              notificationIcon: 'check',
            })
            setTimeout(() => props.storePayload({
              userMessage: null,
            }), 3000)
            props.setMFAStatus(props.database, props.user.uid, props.orgId, false)
          })
          .then(() => {
            Helpers.notifyUser(getAuth().currentUser.email, 'mfa_disabled')
          })
          .catch((err) => {
            if (err.code === 'auth/user-token-expired' ||
                err.code === 'auth/requires-recent-login') {
              reauthenticate('unenroll')
            } else {
              console.log(err)
              props.storePayload({
                notificationType: 3,
                userMessage: 'There was an unknown error. Please try again.',
                notificationIcon: 'warning',
              })
            }
          })
    }
  }

  function reauthenticate(src) {
    if (src) setReauthCaller(src)
    props.storePayload({
      isReauthenticateModal: true,
      isReauthenticateModalShowing: true,
      reauthCaller: src,
    })
  }

  useEffect(() => {
    if (props.reauthPassword) {
      confirmReauth()
    }
  }, [props.reauthPassword])

  function confirmReauth() {
    const auth = getAuth()
    const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        props.reauthPassword,
    )
    reauthenticateWithCredential(auth.currentUser, credential)
        .then(() => {
          props.storePayload({
            isReauthenticateModalShowing: false,
          })
          if (reauthCaller == 'enroll') {
            enrollMFA()
          } else if (reauthCaller === 'unenroll') {
            unenrollMFA()
          } else {
            console.error('bad caller:', reauthCaller)
          }
        })
        .catch((err) => {
          if (err.code === 'auth/wrong-password') {
            props.storePayload({
              notificationType: 3,
              notificationIcon: 'warning',
              userMessage: 'Incorrect password',
            })
          // need further MFA auth
          } else if (err.code === 'auth/multi-factor-auth-required') {
            setMFAResolver(getMultiFactorResolver(auth, err))
            props.storePayload({
              isReauthenticateModalShowing: false,
              isReauthOTPModal: true,
              isReauthOTPModalShowing: true,
            })
          } else {
            console.log('err', err)
            props.storePayload({
              notificationType: 3,
              userMessage: 'There was an unknown error. Please try again.',
              notificationIcon: 'warning',
            })
          }
        })
  }

  useEffect(() => {
    if (props.reauthOTPInput) confirmReauthOTP()
  }, [props.reauthOTPInput])

  function confirmReauthOTP() {
    // assume only 1 factor in resolver.hints
    // see more @ unenrollMFA()
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(MFAResolver.hints[0].uid, props.reauthOTPInput);
    MFAResolver.resolveSignIn(multiFactorAssertion)
        .then(() => {
          props.storePayload({
            isReauthOTPModalShowing: false,
          })
          unenrollMFA()
        })
        .catch((err) => {
          if (err.code === 'auth/invalid-verification-code') {
            props.storePayload({
              notificationType: 3,
              userMessage: 'Incorrect code',
            })
          } else {
            console.log(err)
            props.storePayload({
              notificationType: 3,
              userMessage: 'There was an unknown error. Please try again.',
              notificationIcon: 'warning',
            })
          }
        })
  }

  return (
    <Card
      theme={props.theme}
      title='Two-Factor Authentication'
      titleStyle={{fontSize: 'var(--font-sizes-medium)', marginBottom: 'var(--spacing-01)'}}
    >
      <Switch
        label={props.isMFAEnabled ? 'Enabled' : 'Disabled'}
        checked={isMFAChecked}
        onChange={onMFASwitchChange}
        theme={props.theme}
      />
    </Card>
  )
}

const mapStateToProps = (state) => ({
  database: state.firebaseReducer.database,
  user: state.userReducer.user,
  orgId: state.userReducer.orgId,
  theme: state.userReducer.theme,
  isMFAEnabled: state.userReducer.isMFAEnabled,
  MFAOTPInput: state.userReducer.MFAOTPInput,
  reauthPassword: state.userReducer.reauthPassword,
  reauthOTPInput: state.userReducer.reauthOTPInput,
})

export default connect(mapStateToProps, {
  storePayload,
  setMFAStatus,
  getMFAStatus,
})(MFA)

