import types from '../types'
import 'firebase/compat/auth'
import 'firebase/compat/database'

export const getUserData = (database, uid) => async (dispatch) => {
  const dbRef = database.ref(`users/${uid}`)
  await dbRef.get().then((snapshot) => {
    const val = snapshot.val()
    const userData = val
    const isFirstTimeUser =
      !userData || !userData?.status || !userData?.status?.isTutorialized
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        userData,
        isFirstTimeUser,
        isWelcome: isFirstTimeUser,
      },
    })
  })
}
