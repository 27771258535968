const text = (orgId, authKey) =>
  `OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\"inputUrl\":\"https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf\",\"fileId\":\"abc\",\"metadata\":{},\"webhook\":\"google.com\"}");
Request request = new Request.Builder()
  .url("https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/forms/generic")
  .method("POST", body)
  .addHeader("orgId", ${orgId})
  .addHeader("authKey", ${authKey})
  .addHeader("version", "2")
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();`

export default text
