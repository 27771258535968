import types from '../types'
import 'firebase/compat/auth'
import 'firebase/compat/database'

export const getTrainingSets = (database, orgId) => (dispatch) => {
  const dbRef = database.ref(`organizations/${orgId}/trainingSets`)
  dbRef.get().then((snapshot) => {
    const val = snapshot.val()
    const trainingSetsObj = val
    const trainingSets = trainingSetsObj && Object.keys(trainingSetsObj).map(
        (key) => trainingSetsObj[key],
    )
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        trainingSets,
      },
    })
  })
}
