import types from '../types'

export const getAuthKey = (database, orgId) => async (dispatch) => {
  const dbRef = database.ref(
      `organizations/${orgId}/activeApis/genericFormApi/authToken`,
  )
  await dbRef.on(
      'value',
      (snapshot) => {
        const val = snapshot.val()
        if (val) {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              authKey: val,
            },
          })
        }
      },
      (errorObject) => console.log(errorObject),
  )
}
