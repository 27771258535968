import 'firebase/compat/auth'
import 'firebase/compat/database'

export const logAnalyticsEvent = (name, event) => (dispatch, getState) => {
  const globalState = getState()
  const userReducer = globalState.userReducer
  const firebaseReducer = globalState.firebaseReducer
  const analytics = firebaseReducer?.analytics
  const hasPermission = userReducer.userData?.consentMode?.analytics_storage
  if (analytics && hasPermission) {
    analytics.logEvent(name, event)
  }
}
