const text = (orgId, authKey) =>
  `
 require "uri"
 require "net/http"
 
 url = URI("https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/rikai/custom/your_custom_model_id_HERE")
 
 http = Net::HTTP.new(url.host, url.port);
 request = Net::HTTP::Post.new(url)
 request["orgId"] = ${orgId}
 request["authKey"] = ${authKey}
 form_data = [['file', File.open('file_HERE')],['question', 'question_HERE']]
 request.set_form form_data, 'multipart/form-data'
 response = http.request(request)
 puts response.read_body
 `

export default text
