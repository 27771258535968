import types from '../types'
import 'firebase/compat/auth'
import 'firebase/compat/database'

export const deleteCustomRole = (database, roleName) => (dispatch, getState) => {
  const orgId = getState().userReducer.orgId
  const dbRef = database.ref(`organizations/${orgId}/customRoles/${roleName}`)
  return dbRef
      .remove()
      .then(() => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: `Custom role ${roleName} deleted successfully.`,
            notificationIcon: 'check',
            notificationType: 2,
          },
        })
      })
      .catch((err) => {
        console.log('error', err)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: `Failure deleting custom role ${roleName}.`,
            notificationIcon: 'warning',
            notificationType: 3,
          },
        })
      })
}
