import types from '../types'
import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'

export const initializeAnalytics = () => (dispatch) => {
  const firebaseAppPayload = {
    analytics: firebase.analytics(),
  }

  dispatch({
    type: types.CREATE_FIREBASE_APP,
    payload: firebaseAppPayload,
  })
}
