import React from 'react'
import {connect} from 'react-redux'

import {Button, Icon} from '@lazarusai/forms-ui-components'

import '../styles/Models.css'
import Helpers from '../Helpers'
import {storePayload} from '../actions/storePayload'

function ModelInformation(props) {
  return (
    <div
      className={`selected-model ${props.showAnimate ? 'animate-model': ''}`}
      style={props.width ?
        {width: props.width}:
        {}
      }
    >
      <div className='section'>
        <div
          className='copy-btn'
          onClick={() => {
            Helpers.copyToClipBoard(props.modelInformation[props.selectedModel]?.public?.metadata?.name,
                () => {
                  props.storePayload({
                    userMessage: 'Model Name Copied',
                    notificationIcon: 'check',
                    notificationType: 2,
                  })
                })
          }}
        >
          <Icon animation={null} icon='file-outline'/>
        </div>
        <div className='main-title'>
          {props.modelInformation[props.selectedModel]?.public?.metadata?.name}
        </div>
      </div>
      <div className='section'>
        <div
          className='copy-btn'
          onClick={() => {
            Helpers.copyToClipBoard(props.modelInformation[props.selectedModel]?.public?.metadata?.description,
                () => {
                  props.storePayload({
                    userMessage: 'Model Description Copied',
                    notificationIcon: 'check',
                    notificationType: 2,
                  })
                })
          }}
        >
          <Icon animation={null} icon='file-outline'/>
        </div>
        <div className='subtitle'>
          Model Description
        </div>
        <div className='body'>
          {props.modelInformation[props.selectedModel]?.public?.metadata?.description}
        </div>
      </div>
      {props.modelInformation[props.selectedModel]?.public?.metadata?.summary &&
        <div className='section'>
          <div
            className='copy-btn'
            onClick={() => {
              Helpers.copyToClipBoard(props.modelInformation[props.selectedModel]?.public?.metadata?.summary,
                  () => {
                    props.storePayload({
                      userMessage: 'Model Summary Copied',
                      notificationIcon: 'check',
                      notificationType: 2,
                    })
                  })
            }}
          >
            <Icon animation={null} icon='file-outline'/>
          </div>
          <div className='subtitle'>
            Summary
          </div>
          <div className='body'>
            {props.modelInformation[props.selectedModel]?.public?.metadata?.summary}
          </div>
        </div>
      }
      {props.modelInformation[props.selectedModel]?.public?.metadata?.usage &&
        <div className='section'>
          <div
            className='copy-btn'
            onClick={() => {
              Helpers.copyToClipBoard(props.modelInformation[props.selectedModel]?.public?.metadata?.usage,
                  () => {
                    props.storePayload({
                      userMessage: 'Model Usage Copied',
                      notificationIcon: 'check',
                      notificationType: 2,
                    })
                  })
            }}
          >
            <Icon animation={null} icon='file-outline'/>
          </div>
          <div className='subtitle'>
            Usage
          </div>
          <div className='body'>
            {props.modelInformation[props.selectedModel]?.public?.metadata?.usage}
          </div>
        </div>
      }
      <div className='section'>
        <div
          className='copy-btn'
          onClick={() => {
            Helpers.copyToClipBoard(
                props.modelInformation[props.selectedModel]?.public?.metadata?.modelType,
                () => {
                  props.storePayload({
                    userMessage: 'Model Type Copied',
                    notificationIcon: 'check',
                    notificationType: 2,
                  })
                })
          }}
        >
          <Icon animation={null} icon='file-outline'/>
        </div>
        <div className='subtitle'>
          Model Type
        </div>
        <div className='body'>
          {props.modelInformation[props.selectedModel]?.public?.metadata?.modelType}
        </div>
      </div>
      <div className='section'>
        <div
          className='copy-btn'
          onClick={() => {
            Helpers.copyToClipBoard(props.selectedModel,
                () => {
                  props.storePayload({
                    userMessage: 'Model ID Copied',
                    notificationIcon: 'check',
                    notificationType: 2,
                  })
                })
          }}
        >
          <Icon animation={null} icon='file-outline'/>
        </div>
        <div className='subtitle'>
          Model ID
        </div>
        <div className='body'>
          {props.selectedModel}
        </div>
      </div>
      <div className='section'>
        <div
          className='copy-btn'
          onClick={() => {
            Helpers.copyToClipBoard(process.env.REACT_APP_BASE_URL + props.modelInformation[props.selectedModel]?.public?.metadata?.defaultMethod?.url,
                () => {
                  props.storePayload({
                    userMessage: 'Model URL Copied',
                    notificationIcon: 'check',
                    notificationType: 2,
                  })
                })
          }}
        >
          <Icon animation={null} icon='file-outline'/>
        </div>
        <div className='subtitle'>
          Model URL
        </div>
        <div className='body'>
          {process.env.REACT_APP_BASE_URL + props.modelInformation[props.selectedModel]?.public?.methods?.base64?.url}
        </div>
      </div>
      <div className='section'>
        <div
          className='copy-btn'
          onClick={() => {
            Helpers.copyToClipBoard(Object.keys(props.modelInformation[props.selectedModel]?.public?.methods).map((val) => val.toLowerCase()).join(', '),
                () => {
                  props.storePayload({
                    userMessage: 'Available Methods Copied',
                    notificationIcon: 'check',
                    notificationType: 2,
                  })
                })
          }}
        >
          <Icon animation={null} icon='file-outline'/>
        </div>
        <div className='subtitle'>
          Available Method{`(s)`}
        </div>
        <div className='body'>
          {Object.keys(props.modelInformation[props.selectedModel]?.public?.methods).map((val) => val.toLowerCase()).join(', ')}
        </div>
      </div>
      {props.showDemo &&
        <div className='footer-button'>
          <Button
            text={props.hasAccess ? 'Demo Model': 'Get Access'}
            type={props.hasAccess ? 8: 9}
            theme={props.theme}
            onClick={() => {
              if (props.hasAccess) {
                Helpers.openLinkInNewTab(`/demo?model=${props.selectedModel}`)
              } else {
                Helpers.openLinkInNewTab(`https://emvnha23ura.typeform.com/lazarus-rikai`)
              }
            }}
            style={{marginBottom: '0px'}}
          />
        </div>
      }
    </div>
  )
}


const mapStateToProps = (state, ownProps) => ({
  isLoading: state.userReducer.isLoading,
  theme: state.userReducer.theme,
  modelInformation: state.userReducer.modelInformation,
})

export default connect(
    mapStateToProps,
    {storePayload},
)(ModelInformation)
