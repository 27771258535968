export const getLicenseKey = (database, orgId) => {
  const dbRef = database
      .ref(`organizations/${orgId}/licenseKey`)
  return dbRef.get()
      .then((snapshot) => {
        const licenseKey = snapshot.val()
        return licenseKey
      })
      .catch((err) => {
        console.log('error: ', err)
      })
}
