import React, {useState} from 'react'
import {Link, useSearchParams} from 'react-router-dom'
import {connect} from 'react-redux'

import '../styles/AccountInitialization.css'
import logo from '../images/FormsLogo.png'
import close from '../images/evaIcons/close-outline.svg'
import check from '../images/evaIcons/checkmark-outline.svg'

import {storePayload} from '../actions/storePayload'
import {initializePassword} from '../actions/initializePassword'

function AccountInitialization(props) {
  const checkDictInit = {
    length: false, // length >= 8
    nonAn: false, // use of non-alphanumeric character(s)
    upperLower: false, // use of upper and lower case
    same: false,
    doesntMatchAccessCode: false,
  }

  const [accountForm, setAccountForm] = useState({
    password: '',
    confirmPassword: '',
  })
  const [checkDict, setCheckDict] = useState(checkDictInit)

  const checkToMessageDict = {
    length: 'Passwords must be at least 8 characters long. ', // length >= 8
    nonAn: 'Passwords must have a non-alphanumeric character. ',
    // eslint-disable-next-line max-len
    upperLower: 'Passwords must have both an uppercase and lowercase letter. ',
    same: 'Passwords are not the same. ',
    doesntMatchAccessCode: 'Password cannot be the access code. ',
  }

  const [searchParams] = useSearchParams()

  function checkPasswords(accountForm) {
    checkDict['length'] = Boolean(accountForm['password'].match('^.{8,}$'))
    checkDict['nonAn'] =
      // opposite of all alphanumeric
      !accountForm['password'].match('^[a-zA-Z0-9]*$')
    checkDict['upperLower'] = Boolean(
        accountForm['password'].match('^(?=.*[a-z])(?=.*[A-Z]).*$'),
    )
    checkDict['same'] =
      accountForm['password'] === accountForm['confirmPassword']
    checkDict['doesntMatchAccessCode'] =
      searchParams.get('accessCode') !== accountForm['password']
    setCheckDict(checkDict)
  }

  function onChangeForm(e) {
    const newAccountForm = {...accountForm}
    newAccountForm[e.target.name] = e.target.value
    setAccountForm(newAccountForm)
    checkPasswords(newAccountForm)
  }

  function onSubmit(e) {
    e.preventDefault()
    // Check that passwords are legit.
    let userMessage = ''
    Object.keys(checkDict).forEach((key) => {
      userMessage += !checkDict[key] ? checkToMessageDict[key] : ''
    })
    // if not send notification
    if (userMessage.length) {
      props.storePayload({
        userMessage: userMessage,
        notificationType: 2,
        notificationIcon: 'warning',
      })
    }
    // if good, pass to registration and render new page
    // this is where the loading and setting password would happen
    if (
      Object.keys(checkDict).every((key) => {
        return checkDict[key]
      })
    ) {
      // parse email to get around + in url encoding
      const urlParams = new URLSearchParams(window.location.search.replace('+', '[[PLUS-ICON]]'))
      const emailInter = urlParams.get('user')
      const email = emailInter.replace('[[PLUS-ICON]]', '+')
      props.initializePassword(
          email,
          accountForm['password'],
          searchParams.get('accessCode'),
          props.database,
      )
    }
  }

  return (
    <>
      <div className='custom-modal-init modal'>
        <header className='modal-header'>
          <img src={logo} alt='' />
          <span>Lazarus Forms API</span>
        </header>
        {!props.isSignedIn && !props.initializationMessage && (
          <form onSubmit={onSubmit}>
            <div className='sign-up-inputs'>
              <label htmlFor='password' className='custom-input-label'>
              New Password
              </label>
              <input
                name='password'
                id='password'
                type='password'
                className='custom-input'
                value={accountForm.password}
                onChange={onChangeForm}
              />
              <label htmlFor='confirmPassword' className='custom-input-label'>
              Confirm New Password
              </label>
              <input
                name='confirmPassword'
                id='confirmPassword'
                type='password'
                className='custom-input'
                value={accountForm.confirmPassword}
                onChange={onChangeForm}
              />
              <div className='requirements'>
                <h3>Password must have:</h3>
                <div className='requirement-item'>
                  <p className={checkDict['length'] ? 'highlight' : ''}>
                    {checkDict['length'] && <span>&#10003;</span>} 8 character
                  minimum
                  </p>
                </div>
                <div className='requirement-item'>
                  <p className={checkDict['nonAn'] ? 'highlight' : ''}>
                    {checkDict['nonAn'] && <span>&#10003;</span>} 1
                  non-alphanemerical character
                  </p>
                </div>
                <div className='requirement-item'>
                  <p className={checkDict['upperLower'] ? 'highlight' : ''}>
                    {checkDict['upperLower'] && <span>&#10003;</span>} Includes
                  upper and lowercase
                  </p>
                </div>
                <div className='requirement-item'>
                  <p className={checkDict['same'] ? 'highlight' : ''}>
                    {checkDict['same'] && <span>&#10003;</span>} Passwords match
                  </p>
                </div>
              </div>
            </div>
            <footer>
              <button className='footer-btn cursor-pointer' type='submit'>
              Submit
              </button>
            </footer>
          </form>
        )}
        {props.isSignedIn && (
          <>
            <div className='notification-group'>
              <img className='icon-notify-success' src={check} alt=''></img>
              <div className='text-notify-success'>
                <p>Password changed successfully</p>
              </div>
            </div>
            <footer>
              <Link to='/'>
                <button className='custom-btn cursor-pointer' type='submit'>
                OK
                </button>
              </Link>
            </footer>
          </>
        )}
        {props.initializationMessage && !props.isSignedIn && (
          <>
            <div className='notification-group'>
              <img className='icon-notify-error' src={close} alt=''></img>
              <div className='text-notify-error'>
                <p>{props.initializationMessage}</p>
              </div>
            </div>
            <footer>
              <Link to='/'>
                <button className='custom-btn cursor-pointer' type='submit'>
                Back to login
                </button>
              </Link>
            </footer>
          </>
        )}
      </div>
      <footer className='copyright-footer'>
        &copy; Lazarus Enterprises Inc.
        <br/>
        <a href='https://www.lazarusforms.com/legal#terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms and conditions</a>
        &nbsp;&#x2022;&nbsp;
        <a href='https://www.lazarusforms.com/legal#privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy policy</a>
      </footer>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  cookies: ownProps.cookies,
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  initializationMessage: state.userReducer.initializationMessage,
  isSignedIn: state.userReducer.isSignedIn,
})

export default connect(mapStateToProps, {storePayload, initializePassword})(
    AccountInitialization,
)
