const text = (orgId, authKey) =>
  `OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("text/plain");
RequestBody body = new MultipartBody.Builder().setType(MultipartBody.FORM)
  .addFormDataPart("file",'file_name',
    RequestBody.create(MediaType.parse("application/octet-stream"),
    new File('path_to_file')))
  .build();
Request request = new Request.Builder()
  .url("https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/forms/generic")
  .method("POST", body)
  .addHeader("orgId", ${orgId})
  .addHeader("authKey", ${authKey})
  .addHeader("version", '2')
  .build();
Response response = client.newCall(request).execute();`

export default text
