/* eslint-disable max-len */
const text = (orgId, authKey) =>
  `
import requests

url = "https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/rikai/custom/your_custom_model_id_HERE"

payload={
  'question': 'question_HERE'
}

files=[
  ('file',('file_name',open('file_HERE','rb'),'application/octet-stream'))
]

headers = {
  'orgId': ${orgId},
  'authKey': ${authKey}
}

response = requests.request("POST", url, headers=headers, data=payload, files=files)

print(response.json())
 `

export default text
