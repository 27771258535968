import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import AuthModal from './AuthModal.jsx'
import '../styles/Auth.css'

function Auth(props) {
  const [isAuthModal, setIsAuthModal] = useState(false)
  const [isAuthModalShowing, setIsAuthModalShowing] = useState(false)

  useEffect(() => {
    if (props.isLoading === false && props.isSignedIn === false) {
      setIsAuthModalShowing(true)
    }
    if (props.isSignedIn === false) {
      setIsAuthModal(true)
    } else if (props.isSignedIn === true) {
      setIsAuthModal(false)
      setIsAuthModalShowing(false)
    }
  }, [props.isLoading, props.isSignedIn])

  // const isPasswordReset = window.location.pathname.includes('auth')
  return (
    <>
      {isAuthModal &&
        <div
          className={`auth-page`}
        >
          <AuthModal isShowing={isAuthModalShowing} />
        </div>
      }
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  isSignedIn: state.userReducer.isSignedIn,
  hasAttemptedSignIn: state.userReducer.hasAttemptedSignIn,
  isLoading: state.userReducer.isLoading,
})

export default connect(
    mapStateToProps,
)(Auth)
