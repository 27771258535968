// Dependencies
import React, {useState, useEffect, useRef} from 'react'
import Helpers from '../Helpers.js'
import {Button} from '@lazarusai/forms-ui-components'
import {connect} from 'react-redux'

// Actions
import {storePayload} from '../actions/storePayload'

// Styles
import '../styles/FullLoadingScreen.css'

// Components
import {
//   Animation,
  Loader,
} from '@lazarusai/forms-ui-components'

// Images

function RequestLoadingScreen(props) {
  // eslint-disable-next-line no-unused-vars
  const [time, _setTime] = useState(0)
  const timeRef = useRef(0)
  function setTime(data) {
    _setTime(data)
    timeRef.current = data
  }
  const timeString = Helpers.secondsToTime(timeRef.current)
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(timeRef.current + 1)
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  return (
    <div
      className={'full-loading-screen'}
      style={{
        height: '100%',
        width: '100%',
        backdropFilter: 'blur(7px)',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        position: 'fixed',
        zIndex: 99,
        // display: 'none',
      }}
    >
      {/* <Loader theme={props.theme} /> */}
      <div
        style={{
          position: 'fixed',
          width: '40vw',
          height: '40vh',
          left: '30vw',
          top: '30vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className='standard-animation'>
          {/* <Animation
            animationState={'generating'}
            stopAnimation={false}
            height={'50vh'}
            width={'50vw'}
          /> */}
          <Loader theme={props.theme} />
        </div>
      </div>
      <p
        className={'time-loading'}
        style={{
          color: 'white',
          position: 'fixed',
          top: '10px',
          right: '10px',
        }}
      >
        {timeString}
      </p>
      <Button
        onClick={() => {
          props.canceledRequests.push(props.lastRequest)
          props.storePayload({
            isLoadingRequest: false,
          })
        }}
        text='Abort'
        theme={props.theme}
        type={10}
        style={{
          position: 'fixed',
          left: '50%',
          transform: 'translate(-50%, 0)',
          bottom: '16px',
          width: 'calc(40% - 32px)',
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  theme: state.userReducer.theme,
  canceledRequests: state.userReducer.canceledRequests,
  lastRequest: state.userReducer.lastRequest,
  logsInterval: state.userReducer.logsInterval,
})

export default connect(
    mapStateToProps, {storePayload},
)(RequestLoadingScreen)
