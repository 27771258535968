import types from '../types'
import {getAuth, signInWithEmailAndPassword, setPersistence, inMemoryPersistence} from 'firebase/auth';
import {signOut} from './signOut'

export const signIn = (
    username,
    password,
    isSaveCred,
    database,
) => (dispatch) => {
  const auth = getAuth()
  if (!password || !username) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        userMessage: 'Please include email and password',
        notificationType: 2,
        notificationIcon: 'warning',
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    if (isSaveCred) {
      signInWithEmailAndPassword(auth, username, password)
          .then((user) => {
            checkPasswordIsAccessCode(user.user, password)
                .then((passwordIsAccessCode) => {
                  if (passwordIsAccessCode) {
                    dispatch(signOut());
                    throw new Error('used access code as password')
                  }
                  // else {
                  //   onSignInSuccessIsCalled in App.js when auth is changed
                  // }
                })
                .catch(signInFailureCB)
          })
          .catch(handleMFA)
          .catch(signInFailureCB)
    } else {/* Don't save credentials */
      setPersistence(auth, inMemoryPersistence)
          .then(() => {
            signInWithEmailAndPassword(auth, username, password)
                .then((user) => {
                  checkPasswordIsAccessCode(user.user, password)
                      .then((passwordIsAccessCode) => {
                        if (passwordIsAccessCode) {
                          dispatch(signOut())
                          throw new Error('used access code as password')
                        }
                        // else {
                        //   onSignInSuccessIsCalled in App.js when auth is changed
                        // }
                      })
                      .catch(signInFailureCB)
                })
                .catch(handleMFA)
                .catch(signInFailureCB)
          })
          .catch((error) => console.log(error))
    }
  }
  /* If an error related to MFA is thrown, this handles the logic
     to send and accept an MFA code
  */

  function handleMFA(error) {
    if (error.code === 'auth/multi-factor-auth-required') {
      // Notify user to look for a TOTP code
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          onMFATab: true, // directs AuthModal to switch to the MFA Tab
          mfaError: error, // needs to be passed to the enterMFACode action for some reason
          isLoading: false, // had to add this or it would not stop loading
        },
      })
    } else throw error // propagate to next catch block if error isn't MFA-related
  }

  /*  Response from successful signin is only stored if userData
      is successfully pulled. Userdata is stored along with signedIn/authStatus
      which triggers reroute
  */

  function signInFailureCB(response) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        userMessage: 'Error: Unable to sign in',
        notificationType: 3,
        notificationIcon: 'warning',
        userData: null,
      },
    })
  }

  // AccessCodes are invalid passwords on standard login page
  async function checkPasswordIsAccessCode(user, password) {
    const accessCodesRef = database.ref(`users/${user.uid}/accessCodes`)
    const passwordIsAccessCode = await accessCodesRef
        .get()
        .then((snapshot) => {
          return snapshot.val().hasOwnProperty(password)
        })
        .catch((err) => {
          return false
        })
    return passwordIsAccessCode
  }
}
