const formsMethods = [
  'Upload a file via multipart-form, get back a JSON',
  'Upload a URL via JSON, get back a JSON',
  'Upload a file via base64, get back a JSON',
  'Upload a file via multipart-form, get back a ZIP file',
  'Upload a URL via JSON, get back a ZIP file',
  'Async queue a URL via JSON, send a ZIP file to an output URL',
]

export default formsMethods
