import types from '../types'

export const setMFAStatus = (database, userId, orgId, isMFAEnabled) => (dispatch) => {
  const userRef = database.ref(`organizations/${orgId}/userRoster/${userId}`)
  userRef.child('preferences').get().then((snapshot) => {
    if (snapshot.exists()) {
      userRef.child('preferences').update({'requiresMFA': isMFAEnabled})
    } else {
      userRef.child('preferences').set({'requiresMFA': isMFAEnabled})
    }
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isMFAEnabled,
      },
    })
  }).catch((error) => {
    console.error(error);
  });
}
