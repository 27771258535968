import formsMethods from '../FormsMethods'

import cBase64ToJSON from './c/base64ToJSON'
import cMultipartToJSON from './c/multipartToJSON'
import cURLToJSON from './c/urlToJSON'

import csharpBase64ToJSON from './c_sharp/base64ToJSON'
import csharpMultipartToJSON from './c_sharp/multipartToJSON'
import csharpURLToJSON from './c_sharp/urlToJSON'

import curlBase64ToJSON from './curl/base64ToJSON'
import curlMultipartToJSON from './curl/multipartToJSON'
import curlURLToJSON from './curl/urlToJSON'

import goBase64ToJSON from './go/base64ToJSON'
import goMultipartToJSON from './go/multipartToJSON'
import goURLToJSON from './go/urlToJSON'

import javaBase64ToJSON from './java/base64ToJSON'
import javaMultipartToJSON from './java/multipartToJSON'
import javaURLToJSON from './java/urlToJSON'

import javascriptBase64ToJSON from './javascript/base64ToJSON'
import javascriptMultipartToJSON from './javascript/multipartToJSON'
import javascriptURLToJSON from './javascript/urlToJSON'

import phpBase64ToJSON from './php/base64ToJSON'
import phpMultipartToJSON from './php/multipartToJSON'
import phpURLToJSON from './php/urlToJSON'

import pythonBase64ToJSON from './python/base64ToJSON'
import pythonMultipartToJSON from './python/multipartToJSON'
import pythonURLToJSON from './python/urlToJSON'

import rubyBase64ToJSON from './ruby/base64ToJSON'
import rubyMultipartToJSON from './ruby/multipartToJSON'
import rubyURLToJSON from './ruby/urlToJSON'

import swiftBase64ToJSON from './swift/base64ToJSON'
import swiftMultipartToJSON from './swift/multipartToJSON'
import swiftURLToJSON from './swift/urlToJSON'

const identificationCodeSnippets = (orgId, authKey) => ({
  'c': {
    [formsMethods[0]]: cMultipartToJSON(orgId, authKey),
    [formsMethods[1]]: cURLToJSON(orgId, authKey),
    [formsMethods[2]]: cBase64ToJSON(orgId, authKey),
  },
  'c#': {
    [formsMethods[0]]: csharpMultipartToJSON(orgId, authKey),
    [formsMethods[1]]: csharpURLToJSON(orgId, authKey),
    [formsMethods[2]]: csharpBase64ToJSON(orgId, authKey),
  },
  'curl': {
    [formsMethods[0]]: curlMultipartToJSON(orgId, authKey),
    [formsMethods[1]]: curlURLToJSON(orgId, authKey),
    [formsMethods[2]]: curlBase64ToJSON(orgId, authKey),
  },
  'go': {
    [formsMethods[0]]: goMultipartToJSON(orgId, authKey),
    [formsMethods[1]]: goURLToJSON(orgId, authKey),
    [formsMethods[2]]: goBase64ToJSON(orgId, authKey),
  },
  'java': {
    [formsMethods[0]]: javaMultipartToJSON(orgId, authKey),
    [formsMethods[1]]: javaURLToJSON(orgId, authKey),
    [formsMethods[2]]: javaBase64ToJSON(orgId, authKey),
  },
  'javascript': {
    [formsMethods[0]]: javascriptMultipartToJSON(orgId, authKey),
    [formsMethods[1]]: javascriptURLToJSON(orgId, authKey),
    [formsMethods[2]]: javascriptBase64ToJSON(orgId, authKey),
  },
  'php': {
    [formsMethods[0]]: phpMultipartToJSON(orgId, authKey),
    [formsMethods[1]]: phpURLToJSON(orgId, authKey),
    [formsMethods[2]]: phpBase64ToJSON(orgId, authKey),
  },
  'python': {
    [formsMethods[0]]: pythonMultipartToJSON(orgId, authKey),
    [formsMethods[1]]: pythonURLToJSON(orgId, authKey),
    [formsMethods[2]]: pythonBase64ToJSON(orgId, authKey),
  },
  'ruby': {
    [formsMethods[0]]: rubyMultipartToJSON(orgId, authKey),
    [formsMethods[1]]: rubyURLToJSON(orgId, authKey),
    [formsMethods[2]]: rubyBase64ToJSON(orgId, authKey),
  },
  'swift': {
    [formsMethods[0]]: swiftMultipartToJSON(orgId, authKey),
    [formsMethods[1]]: swiftURLToJSON(orgId, authKey),
    [formsMethods[2]]: swiftBase64ToJSON(orgId, authKey),
  },
})

export default identificationCodeSnippets
