import types from '../types'
import 'firebase/compat/auth'
import 'firebase/compat/database'

export const setCustomRole = (database, roleName, roleBody) => (dispatch, getState) => {
  const orgId = getState().userReducer.orgId
  const dbRef = database.ref(`organizations/${orgId}/customRoles/${roleName}`)
  return dbRef
      .set(roleBody)
      .then(() => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: `Custom role ${roleName} set successfully.`,
            notificationIcon: 'check',
            notificationType: 2,
          },
        })
        return true
      })
      .catch((err) => {
        console.log('error', err)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: `Failure setting custom role ${roleName}.`,
            notificationIcon: 'warning',
            notificationType: 3,
          },
        })
        return false
      })
}
