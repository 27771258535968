/* eslint-disable max-len */
/**
 * Function to get all of the custom models requests for a certain org.
 */

import types from '../types'
import {ref, child, get} from 'firebase/database'

export const getOrgModels = (database, orgId) => (dispatch, getState) => {
  return get(child(
      ref(database),
      `organizations/${orgId}/models`),
  ).then((snapshot) => {
    if (snapshot.exists()) {
      const newOrgModels = snapshot.val()
      const orgModels = getState().userReducer.orgModels || {}
      orgModels[orgId] = newOrgModels
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          orgModels,
        },
      })
    }
  }).catch((err) => {
    console.error(err)
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        userMessage: 'Error pulling organization model data.',
        notificationIcon: 'warning',
        notificationType: 2,
      },
    })
    return false
  })
}
