import types from '../types'
import 'firebase/compat/auth'
import 'firebase/compat/database'

export const setNames = (database, first, last, userData, userId) => (dispatch) => {
  const newData ={
    ...userData,
    'firstName': first,
    'lastName': last,
  }
  const dbRef = database.ref(`users/${userId}`)
  return dbRef
      .set(newData)
      .then(() => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userData: newData,
            userMessage: 'Name successfully changed',
            notificationIcon: 'check',
            notificationType: 2,
          },
        })
        return true
      })
      .catch((err) => {
        console.log('error', err)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Error setting first and last name.',
            notificationIcon: 'warning',
            notificationType: 3,
          },
        })
        return false
      })
}
