import types from '../types'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/analytics'
import firebaseConfig from '../config'

export const createFirebaseApp = () => (dispatch) => {
  const firebaseApp = !firebase.apps.length ?
    firebase.initializeApp(firebaseConfig) :
    firebase.app()

  const firebaseAppPayload = {
    database: firebaseApp.database(),
    firestore: firebaseApp.firestore(),
    storage: firebaseApp.storage(),
  }

  dispatch({
    type: types.CREATE_FIREBASE_APP,
    payload: firebaseAppPayload,
  })
}
