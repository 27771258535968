import React, {useState, useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload'
import {markUserTutorialized} from '../actions/markUserTutorialized'
import {Modal} from '@lazarusai/forms-ui-components'

function Welcome(props) {
  const welcomeContent = (
    <>
      <p>
        Welcome to Lazarus Forms API Dashboard!
        <br/><br/><br/>
        This is where you can manage your organization&apos;s API usage and learn how to use our APIs.
        <br/><br/><br/>
        Would you like to try a demo of our API?
      </p>
    </>
  )
  const [isRedirect, setIsRedirect] = useState(false)
  function onClickSkip() {
    props.markUserTutorialized(props.database, props.user)
    props.storePayload({
      isWelcome: false,
    })
  }

  function onClickConfirm() {
    setIsRedirect(true)
  }

  useEffect(() => {
    if (isRedirect) {
      setTimeout(() => {
        props.storePayload({isWelcome: false})
      }, [0])
    }
  }, [isRedirect])

  if (isRedirect) {
    return <Navigate to='/demo' />
  } else {
    return (
      <div style={{fontFamily: 'Anonymous Pro'}}>
        <Modal
          content={welcomeContent}
          isVisible={true}
          theme={props.theme}
          height='50vh'
          showClose={false}
          showCancel
          onConfirm={onClickConfirm}
          onCancel={onClickSkip}
          confirmText={'Take me to the Demo'}
          cancelText={'Skip'}
          showConfirm
          outsideClickDismisses={true}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  userMessage: state.userReducer.userMessage,
  user: state.userReducer.user,
  database: state.firebaseReducer.database,
  theme: state.userReducer.theme,
})

export default connect(mapStateToProps, {storePayload, markUserTutorialized})(
    Welcome,
)
