import React, {Fragment, useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {Input, Card, Select, Button} from '@lazarusai/forms-ui-components'

// import Helpers from '../Helpers'
import {storePayload} from '../actions/storePayload'
import {updateSubOrganizationLimit} from '../actions/updateSubOrganizationLimit'
import {createSubOrganizationLimit} from '../actions/createSubOrganizationLimit'
import {deleteSubOrganizationLimit} from '../actions/deleteSubOrganizationLimit'
import '../styles/LimitInputs.css'
import {getSubOrganizationLimits} from '../actions/getSubOrganizationLimits'

function LimitInputs({
  canChangeLimits=true,
  subOrgId=null,
  ...props
}) {
  const [currentLimits, setCurrentLimits] = useState({})
  const [newLimit, setNewLimit] = useState({})

  useEffect(() => {
    if (props.subOrganizationLimits) {
      setCurrentLimits(structuredClone(props.subOrganizationLimits))
    }
  }, [props.subOrganizationLimits])

  function isLimitValid(limitObj) {
    let isValid = true
    const validKeys = ['period', 'usageType', 'limit']
    if (limitObj?.period === 'range') {
      validKeys.push('startDate', 'endDate')
    }
    if (!limitObj?.model && !(limitObj?.models?.length > 0)) { // new limit
      isValid = false
    }
    for (let kIndex = 0; kIndex < validKeys.length; kIndex++) {
      if (!limitObj?.[validKeys[kIndex]]) {
        isValid = false
      }
    }
    return isValid
  }

  function haveLimitsChanged(changed, initial) {
    const changedKeys = Object.keys(changed || {})
    let areThereChanges = false
    for (let cIndex = 0; cIndex < changedKeys.length; cIndex++) {
      if (Array.isArray(changed?.[changedKeys[cIndex]]) && changed?.[changedKeys[cIndex]]?.length > 0 && (changed?.[changedKeys[cIndex]]?.sort() !== initial?.[changedKeys[cIndex]]?.sort())) {
        areThereChanges = true
      } else {
        if (changed?.[changedKeys[cIndex]] !== initial?.[changedKeys[cIndex]]) {
          areThereChanges = true
        }
      }
    }
    return !areThereChanges
  }

  const limitInputs = (usageId) => {
    const isNewLimitInput = usageId === null
    return (
      <Card
        theme={props.theme}
        title={isNewLimitInput ? 'New Limit': `Existing Limit (Usage Id: ${usageId})`}
        className={`limit-inputs ${isNewLimitInput ? '': 'limit-inputs-existing'}`}
      >
        <Select
          value={isNewLimitInput ? newLimit?.model : currentLimits[usageId]?.model}
          label={'Model'}
          theme={props.theme}
          isSelectedYellow={true}
          options={props.modelAccess ? [
            'total - All model usage',
            ...props.modelAccess.
                filter((modelId) => props.modelInformation?.[modelId]?.public).
                map((modelId) => `${modelId} - ${props.modelInformation?.[modelId]?.public?.metadata?.name}`),
          ] :
                []}
          onClickOption={(option) => {
            if (canChangeLimits && isNewLimitInput) {
              const newNewLimit = structuredClone(newLimit)
              newNewLimit.model = option.split(' - ')[0]
              setNewLimit(newNewLimit)
            }
          }}
        />
        <Select
          label='Usage Type'
          theme={props.theme}
          isSelectedYellow={true}
          options={['question', 'page']}
          value={(isNewLimitInput ? newLimit?.usageType : currentLimits[usageId]?.usageType)}
          onClickOption={(option) => {
            if (canChangeLimits) {
              if (isNewLimitInput) {
                const newNewLimit = structuredClone(newLimit)
                newNewLimit.usageType = option
                setNewLimit(newNewLimit)
              } else {
                const newCurrentLimits = structuredClone(currentLimits)
                newCurrentLimits[usageId].usageType = option
                setCurrentLimits(newCurrentLimits)
              }
            }
          }}
        />
        <Input
          theme={props.theme}
          type={1}
          inputType={'number'}
          label={'Usage Limit'}
          value={`${(isNewLimitInput ? newLimit?.limit : currentLimits[usageId]?.limit)}`}
          onChange={(e) => {
            if (canChangeLimits) {
              if (isNewLimitInput) {
                const newNewLimit = structuredClone(newLimit)
                newNewLimit.limit = parseInt(e.target.value)
                setNewLimit(newNewLimit)
              } else {
                const newCurrentLimits = structuredClone(currentLimits)
                newCurrentLimits[usageId].limit = parseInt(e.target.value)
                setCurrentLimits(newCurrentLimits)
              }
            }
          }}
          placeholder={'Enter the usage limit value...'}
        />
        <Select
          label='Limit Period'
          theme={props.theme}
          isSelectedYellow={true}
          options={['daily', 'monthly', 'yearly', 'range', 'future']}
          value={(isNewLimitInput ? newLimit?.period : currentLimits[usageId]?.period)}
          onClickOption={(option) => {
            if (canChangeLimits) {
              if (isNewLimitInput) {
                const newNewLimit = structuredClone(newLimit)
                newNewLimit.period = option
                setNewLimit(newNewLimit)
              } else {
                const newCurrentLimits = structuredClone(currentLimits)
                newCurrentLimits[usageId].period = option
                setCurrentLimits(newCurrentLimits)
              }
            }
          }}
        />
        {(isNewLimitInput ? newLimit?.period === 'range': currentLimits[usageId]?.period === 'range') &&
          <>
            {/* range date input */}
            <Input
              theme={props.theme}
              type={1}
              inputType={'date'}
              label={'Start date'}
              value={(isNewLimitInput ? newLimit?.startDate : currentLimits[usageId]?.startDate)?.slice(0, 10) || ''}
              onChange={(e) => {
                if (canChangeLimits) {
                  if (isNewLimitInput) {
                    const newNewLimit = structuredClone(newLimit)
                    newNewLimit.startDate = e.target.value + 'T00:00:00'
                    setNewLimit(newNewLimit)
                  } else {
                    const newCurrentLimits = structuredClone(currentLimits)
                    newCurrentLimits[usageId].startDate = e.target.value + 'T00:00:00'
                    setCurrentLimits(newCurrentLimits)
                  }
                }
              }}
            />
            <Input
              theme={props.theme}
              type={1}
              inputType={'date'}
              label={'End date'}
              value={(isNewLimitInput ? newLimit?.endDate : currentLimits[usageId]?.endDate)?.slice(0, 10) || ''}
              onChange={(e) => {
                if (canChangeLimits) {
                  if (isNewLimitInput) {
                    const newNewLimit = structuredClone(newLimit)
                    newNewLimit.endDate = e.target.value + 'T00:00:00'
                    setNewLimit(newNewLimit)
                  } else {
                    const newCurrentLimits = structuredClone(currentLimits)
                    newCurrentLimits[usageId].endDate = e.target.value + 'T00:00:00'
                    setCurrentLimits(newCurrentLimits)
                  }
                }
              }}
            />
          </>
        }
        <div
          className={`limit-action-row ${isNewLimitInput ? '': 'limit-split-action-row'}`}
        >
          {isNewLimitInput ?
            <>
              <Button
                text={'Create New Limit'}
                theme={props.theme}
                disabled={haveLimitsChanged(
                    newLimit,
                    {},
                ) || !isLimitValid(newLimit)}
                onClick={onCreateLimit}
              />
            </> :
            <>
              <Button
                text={'Update Limit'}
                theme={props.theme}
                disabled={haveLimitsChanged(
                    currentLimits[usageId],
                    props.subOrganizationLimits[usageId],
                ) || !isLimitValid(currentLimits[usageId])}
                onClick={() => onUpdateLimit(usageId)}
              />
              <Button
                text={'Delete Limit'}
                theme={props.theme}
                type={10}
                onClick={() => onDeleteLimit(usageId)}
              />
            </>
          }
        </div>
      </Card>
    )
  }

  async function onDeleteLimit(usageId) {
    const isUserSure = confirm('Are you sure you want to remove this limit?')
    if (isUserSure) {
      await props.deleteSubOrganizationLimit(subOrgId, usageId)
      props.getSubOrganizationLimits(subOrgId)
    }
  }

  async function onUpdateLimit(usageId) {
    const body = structuredClone(currentLimits[usageId])
    await props.updateSubOrganizationLimit(subOrgId, usageId, body)
    props.getSubOrganizationLimits(subOrgId)
  }

  async function onCreateLimit() {
    const body = structuredClone(newLimit)
    body['type'] = 'usage'
    const success = await props.createSubOrganizationLimit(subOrgId, body)
    if (success) {
      setNewLimit({})
    }
    props.getSubOrganizationLimits(subOrgId)
  }

  return (<>
    <div className='limit-header'>Limits</div>
    <div
      className={'limits'}
    >
      {Object.keys(props.subOrganizationLimits || {}).map((usageId) => {
        return (<Fragment
          key={`${usageId}-limit-fragment`}
        >
          {limitInputs(usageId)}
        </Fragment>)
      })}
      {limitInputs(null)}
    </div>
  </>)
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  usersObj: state.userReducer.usersObj,
  users: state.userReducer.users,
  userData: state.userReducer.userData,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  theme: state.userReducer.theme,
  orgId: state.userReducer.orgId,
  subOrganizationMetrics: state.userReducer.subOrganizationMetrics,
  subOrganizationDetails: state.userReducer.subOrganizationDetails,
  modelAccess: state.userReducer.modelAccess,
  modelInformation: state.userReducer.modelInformation,
  authKey: state.userReducer.authKey,
  vkgDomain: state.userReducer.vkgDomain,
  subOrgAuthKey: state.userReducer.subOrgAuthKey,
  subOrganizationLimits: state.userReducer.subOrganizationLimits,
})

export default connect(mapStateToProps, {
  storePayload, updateSubOrganizationLimit, createSubOrganizationLimit,
  deleteSubOrganizationLimit, getSubOrganizationLimits,
})(LimitInputs)
