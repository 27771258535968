import formsCodeSnippets from './CodeSnippets/forms/FormsCodeSnippets'
import formsV2CodeSnippets from './CodeSnippets/formsV2/FormsV2CodeSnippets'
import ocrCodeSnippets from './CodeSnippets/ocr/OCRCodeSnippets'
import receiptsCodeSnippets from './CodeSnippets/receipts/ReceiptsCodeSnippets'
import invoicesCodeSnippets from './CodeSnippets/invoices/InvoicesCodeSnippets'
import customCodeSnippets from './CodeSnippets/custom/CustomCodeSnippets'
import ssnCodeSnippets from './CodeSnippets/ssn/SSNCodeSnippets'
import rikAICodeSnippets from './CodeSnippets/rikai/RikACodeSnippets'
import identificationCodeSnippets from './CodeSnippets/identification/IdentificationCodeSnippets'

const codeSnippets = (orgId, authKey) => ({
  forms: formsCodeSnippets(orgId, authKey),
  formsV2: formsV2CodeSnippets(orgId, authKey),
  ocr: ocrCodeSnippets(orgId, authKey),
  receipts: receiptsCodeSnippets(orgId, authKey),
  invoices: invoicesCodeSnippets(orgId, authKey),
  custom: customCodeSnippets(orgId, authKey),
  ssn: ssnCodeSnippets(orgId, authKey),
  rikAI: rikAICodeSnippets(orgId, authKey),
  identification: identificationCodeSnippets(orgId, authKey),
})

export default codeSnippets
