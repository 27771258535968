import types from '../types'


export const getVKGDomain = (database, orgId) => async (dispatch) => {
  const dbRef = database.ref(`organizations/${orgId}/vkgDomain`)
  await dbRef
      .get()
      .then((snapshot) => {
        const val = snapshot.val()

        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            vkgDomain: val,
          },
        })
      })
      .catch((err) => {
        console.log('error', err)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Error pulling vkg domain.',
            notificationIcon: 'warning',
            notificationType: 2,
          },
        })
      })
}
