import React, {useState, useEffect, useCallback} from 'react'
import {connect} from 'react-redux'
import codingLanguageList from '../CodingLanguages.js'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import {
  Button,
  Input,
  Icon,
  Card,
  Modal,
  Switch,
} from '@lazarusai/forms-ui-components'
import {storePayload} from '../actions/storePayload.js'
import {enterMFACode} from '../actions/enterMFACode'
import {setDefaultLanguage} from '../actions/setDefaultLanguage.js'
import {setNames} from '../actions/setNames.js'
import {removeAnalytics} from '../actions/removeAnalytics.js'
import {initializeAnalytics} from '../actions/initializeAnalytics.js'
import FullLoadingScreen from './FullLoadingScreen.jsx'
import Helpers from '../Helpers.js'
import MFA from './MFA.jsx'

function Profile(props) {
  const [isProfileLoading, setIsProfileLoading] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [analyticsConsent, setAnalyticsConsent] = useState(false)

  const [codingLanguage, setCodingLanguage] = useState(undefined)

  const [currentPassword, setCurrentPassword] = useState('')
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [newPassword1, setNewPassword1] = useState('')
  const [showNewPassword1, setShowNewPassword1] = useState(false)
  const [newPassword2, setNewPassword2] = useState('')
  const [showNewPassword2, setShowNewPassword2] = useState(false)

  const [passwordCheck, setPasswordCheck] = useState({
    '8 characters minimum': false,
    'Includes upper and lower case': false,
    'Includes special character': false,
    'Passwords must match': false,
  })

  const [errorModalShowing, setErrorModalShowing] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [newDefaults, setNewDefaults] = useState(null)
  const [newUserData, setNewUserData] = useState(null)

  const [isButtonLower, setIsButtonLower] = useState(false)
  const [isButtonLowerShowing, setIsButtonLowerShowing] = useState(false)

  const [MFAOTPInput, setMFAOTPInput] = useState('')
  const [isMFALoading, setIsMFALoading] = useState(false)

  function onSubmitMFA(e) {
    setIsMFALoading(true)
    e.preventDefault()
    props.enterMFACode(MFAOTPInput.slice(0, 3) + MFAOTPInput.slice(4, 7), props.mfaError)
        .then((res) => {
          if (res.user) /* success condition */ {
            const user = firebase.auth().currentUser
            user
                .updatePassword(newPassword1)
                .then(() => {
                  props.storePayload({
                    userMessage: 'Password successfully changed',
                    notificationIcon: 'check',
                    notificationType: 1,
                    showingMFA: false,
                  })
                }, (error) => {
                  console.log('error: ', error)
                  props.storePayload({
                    userMessage: 'Error resetting password',
                    notificationIcon: 'warning',
                    notificationType: 3,
                  })
                })
                .catch((error) => {
                  console.log('error: ', error)
                  props.storePayload({
                    userMessage: 'Error resetting password',
                    notificationIcon: 'warning',
                    notificationType: 3,
                  })
                })
                .finally(() => {
                  setCurrentPassword('')
                  setNewPassword1('')
                  setNewPassword2('')
                  setMFAOTPInput('')
                })
          } else {
            props.storePayload({
              userMessage: 'Invalid code - please try again.',
              notificationType: 3,
              notificationIcon: 'warning',
            })
          }
        })
        .catch((err) => {
          console.log('error: ', err)
          props.storePayload({
            userMessage: 'Invalid code - please try again.',
            notificationType: 3,
            notificationIcon: 'warning',
          })
        })
        .finally(() => {
          setIsMFALoading(false)
        })
  }

  function formatOTPInput(input) {
    // numbers only
    if (isNaN(input[input.length - 1])) return input.slice(0, -1)
    // auto add '-'
    if (input.length > 3 && !input.includes('-')) return input.slice(0, 3) + '-' + input.slice(3, input.length)
    // no longer than 6 digits (not counting dash)
    if (input.length > 7) return input.slice(0, 7)
    return input
  }

  function isValidOTP(input) {
    return (input.length === 7 && !isNaN(input.slice(0, 3) && !isNaN(input.slice(4, 7))))
  }

  useEffect(() => {
    if (props.user && props.orgDetails && props.userData) {
      if ((props.userData?.firstName === newUserData?.firstName &&
        props.userData?.lastName === newUserData?.lastName &&
        props.orgDetails?.defaults?.defaultLanguage === newDefaults?.defaultLanguage &&
        props.userData?.consentMode?.analytics_storage === newUserData?.analytics_storage) ||
        (newDefaults === null && newUserData === null)) {
        // only set fields on load or when all new data has been stored in props/redux
        fillFieldsFromProps()
        setIsProfileLoading(false)
      }
      handleLowerButtonRender
    }
  }, [props.user, props.orgDetails, props.userData])

  useEffect(() => {
    const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
    setPasswordCheck({
      '8 characters minimum': newPassword1.length >= 8,
      'Includes upper and lower case':
        newPassword1.toUpperCase() !== newPassword1 &&
        newPassword1.toLowerCase() !== newPassword1,
      'Includes special character': specialCharRegex.test(newPassword1),
      'Passwords must match': newPassword1 && newPassword2 && newPassword1 === newPassword2,
    })
  }, [newPassword1, newPassword2])

  // stores that there are changes to profile
  useEffect(() => {
    if (email) { // runs only once state is set from props (user should always have email)
      const isNewPasswordValid = Object.values(passwordCheck).every((el) => el)
      const areThereProfileChanges = (
        (props.userData && props.orgDetails) && (
          (
            isNewPasswordValid &&
            (
              currentPassword.length > 0 ||
              newPassword1.length > 0 ||
              newPassword2.length > 0
            )
          ) ||
          (
            (firstName.length > 0 || lastName.length > 0) &&
            (firstName !== props?.userData?.firstName || lastName !== props?.userData?.lastName) &&
            ([...firstName].filter((char) => char !== ' ').length + [...lastName].filter((char) => char !== ' ').length)
          ) ||
          (codingLanguage !== props?.orgDetails?.defaults?.defaultLanguage) ||
          (analyticsConsent !== (props.userData?.consentMode?.analytics_storage === 'granted'))
        )
      )
      props.storePayload({
        profileChangesMade: areThereProfileChanges,
      })
    }
  }, [currentPassword, newPassword1, newPassword2, lastName, firstName, analyticsConsent, codingLanguage, passwordCheck])

  // need useCallback to memoize the function so it doesn't redefine each render
  // otherewise removeEventListener won't work because it'll be a 'different' function
  const preventNavigation = useCallback((event) => {
    event.preventDefault()
  }, [])

  useEffect(() => {
    if (props.profileChangesMade) {
      props.storePayload({
        preventNavigationListener: preventNavigation,
      })
      window.addEventListener('beforeunload', preventNavigation)
    } else {
      window.removeEventListener('beforeunload', props.preventNavigationListener)
    }
    handleLowerButtonRender(props.profileChangesMade)
  }, [props.profileChangesMade])

  async function updateName(first, last) {
    const success = await props.setNames(props.database, first, last, props.userData, props.user.uid)
    return success
  }

  function handleMFA(error) {
    if (error.code === 'auth/multi-factor-auth-required') {
      // Notify user to look for a TOTP code
      props.storePayload({
        userMessage: 'This account has Two-Factor Authentication enabled. Retrieve the six-digit code from your authenticator app and enter it below',
        notificationIcon: 'warning',
        notificationType: 2,
      })
      props.storePayload({
        showingMFA: true, // directs AuthModal to switch to the MFA Tab
        mfaError: error, // needs to be passed to the enterMFACode action for some reason
        isLoading: false, // had to add this or it would not stop loading
      })
    } else {
      props.storePayload({
        userMessage: 'Error resetting password',
        notificationIcon: 'warning',
        notificationType: 3,
      })
      console.log('error: ', error)
    }
  }

  async function updatePassword() {
    const user = firebase.auth().currentUser
    const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        currentPassword,
    )

    let success = false

    // authenticate current password
    return user
        .reauthenticateWithCredential(credential)
        .then(() => {
          user
              .updatePassword(newPassword1)
              .then(() => {
                Helpers.notifyUser(firebase.auth().currentUser.email)
              })
              .then(() => {
                props.storePayload({
                  userMessage: 'Password successfully changed',
                  notificationIcon: 'check',
                  notificationType: 2,
                })
                success = true
              }, (error) => {
                console.log('error: ', error)
                handleMFA(error)
                props.storePayload({
                  userMessage: 'Error resetting password',
                  notificationIcon: 'warning',
                  notificationType: 3,
                })
              })
              .catch((error) => {
                console.log('error: ', error)
                props.storePayload({
                  userMessage: 'Error resetting password',
                  notificationIcon: 'warning',
                  notificationType: 3,
                })
              })
              .finally(() => {
                setCurrentPassword('')
                setNewPassword1('')
                setNewPassword2('')
                return success
              })
        })
        .catch((error) => {
          console.log('error: ', error)
          handleMFA(error)
          return success
        })
  }

  function fillFieldsFromProps() {
    if (props.user && props.userData && props.orgDetails) {
      setFirstName(props.userData?.firstName ? props.userData.firstName : '')
      setLastName(props.userData?.lastName ? props.userData.lastName : '')
      setEmail(props.user.email)
      setCurrentPassword('')
      setNewPassword1('')
      setNewPassword2('')
      const language = props.orgDetails?.defaults?.defaultLanguage || undefined
      setCodingLanguage(Helpers.capitalizeFirstLetter(language))
      setAnalyticsConsent(props.userData?.consentMode?.['analytics_storage'] === 'granted')
    }
  }

  const revertChanges = () => {
    fillFieldsFromProps()
    props.storePayload({
      userMessage:
      'Changes reverted.',
      notificationType: 2,
      notificationIcon: 'check',
    })
    setIsButtonLowerShowing(false)
    // remove the EventListener since no changes need to be saved
    window.removeEventListener('beforeunload', props.preventNavigationListener)
  }

  const saveChanges = async () => {
    const listOfChanges = []; // list of all the things changed
    // password
    const promises = []
    if (
      currentPassword.length > 0 ||
      newPassword1.length > 0 ||
      newPassword2.length > 0
    ) {
      const validNewPassword = Object.values(passwordCheck).every((el) => el)
      if (validNewPassword) {
        promises.push(new Promise(async (resolve) => {
          const success = await updatePassword()
          resolve(success)
        }))
        listOfChanges.push('password')
      } else {
        setErrorModalShowing(true)
        setErrorMessage('Invalid new password.')
      }
    }
    // new state for useEffect
    setNewDefaults({'defaultLanguage': codingLanguage})
    setNewUserData({
      'firstName': firstName,
      'lastName': lastName,
      'analytics_storage': analyticsConsent,
    })
    // names
    if ((firstName.length > 0 || lastName.length > 0) &&
      (firstName !== props?.userData?.firstName || lastName !== props?.userData?.lastName) &&
      ([...firstName].filter((char) => char !== ' ').length + [...lastName].filter((char) => char !== ' ').length)
    ) {
      promises.push(new Promise(async (resolve) => {
        const success = await updateName(firstName, lastName)
        resolve(success)
      }))
      listOfChanges.push('name')
    }
    // coding language
    if (codingLanguage !== props?.orgDetails?.defaults?.defaultLanguage) {
      promises.push(new Promise(async (resolve) => {
        const success = await props.setDefaultLanguage(props.database, codingLanguage, props?.orgDetails?.defaults || {}, props.orgId)
        resolve(success)
      }))
      listOfChanges.push('language')
    }
    // cookies
    if (analyticsConsent !== (props.userData?.consentMode?.analytics_storage === 'granted')) {
      listOfChanges.push('cookies')
      if (analyticsConsent) {
        promises.push(new Promise(async (resolve) => {
          const success = await acceptCookies()
          resolve(success)
        }))
      } else {
        promises.push(new Promise(async (resolve) => {
          const success = await denyCookies()
          resolve(success)
        }))
      }
    }

    /*
      In the future, the following should be triggered in the promise chains
      of the actions that handle storing changes to database
    */
    const successes = await Promise.all(promises)

    if (successes.every((x) => x === true)) {
      props.storePayload({
        profileChangesMade: false,
        userMessage: `The following changes were made: ${listOfChanges.join(', ')}`,
        notificationIcon: 'check',
        notificationType: 2,
      })
    } else {
      const combined = successes.map((succ, i) => `${listOfChanges[i]} - [${succ ? 'Success': 'Failed'}]`)
      props.storePayload({
        profileChangesMade: false,
        userMessage: `Not all changes saved: ${combined.join(', ')}`,
        notificationIcon: 'check',
        notificationType: 2,
      })
    }
    window.removeEventListener('beforeunload', props.preventNavigationListener)
  }

  function adjustConsent(type, value) {
    if (props.user.uid) {
      const dbUrl = `users/${props.user.uid}`
      const dbRef = props.database.ref(dbUrl)
      return dbRef.update({[type]: value})
          .then(() => {
            props.storePayload({
              userMessage: 'User data saved',
              notificationIcon: 'check',
              notificationType: 2,
              userData: {
                ...props.userData,
                [type]: value,
              },
            })
            return true
          })
          .catch((error) => {
            props.storePayload({
              userMessage: 'There was an error saving user data',
              notificationIcon: 'warning',
              notificationType: 3,
            })
            console.log('error: ', error)
            return false
          })
    }
    return false
  }

  async function acceptCookies() {
    const success = await adjustConsent('consentMode', {
      'analytics_storage': 'granted',
      'ad_storage': 'denied',
    })
    props.initializeAnalytics()
    if (props.isSaveCred) {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    }
    return success
  }

  async function denyCookies() {
    const success = await adjustConsent('consentMode', {
      'analytics_storage': 'denied',
      'ad_storage': 'denied',
    })
    localStorage.clear()
    sessionStorage.clear()
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    props.removeAnalytics()
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
    return success
  }

  function onSwitchChange(e) {
    setAnalyticsConsent(e.target.checked)
  }

  function handleLowerButtonRender(profileChangesMade) {
    if (profileChangesMade) {
      setIsButtonLowerShowing(true)
      setIsButtonLower(true)
    } else {
      setIsButtonLowerShowing(false)
    }
  }

  const mfa = (
    <form className='profile-mfa-form' onSubmit={onSubmitMFA}>
      <p>
        This account has Two-Factor Authentication enabled. Retrieve the six-digit code from your authenticator app and enter it below.
      </p>
      <Input
        type={1}
        label='Authentication Code'
        onChange={(e) => {
          setMFAOTPInput(formatOTPInput(e.target.value))
        }}
        placeholder = '000-000'
        theme={props.theme}
        value={MFAOTPInput}
        key='MFAInput'
        autocomplete={false}
      />
      <div>
        <Button
          theme={props.theme}
          disabled={!isValidOTP(MFAOTPInput)}
          buttonType='submit'
          text='Enter Code'
          icon={<Icon icon='arrow-forward-outline' />}
          iconPosition='right'
          iconJustify='edge'
          loading={isMFALoading}
        />
        {/* <Button
          theme={props.theme}
          type='ghost-error'
          onClick={() => props.storePayload({showingMFA: false})}
          text='Cancel'
          icon={<Icon icon='close-outline' />}
          iconPosition='right'
          iconJustify='edge'
        /> */}
      </div>
    </form>
  )

  return (
    <div className='profile-container'>
      {isProfileLoading ? (
        <FullLoadingScreen theme={props.theme} />
      ) : (
        <>
          <Modal
            isVisible={errorModalShowing}
            content={errorMessage}
            confirmText={'Close'}
            theme={props.theme}
            onConfirm={(e) => {
              e.preventDefault()
              setErrorModalShowing(false)
              setErrorMessage('')
            }}
            outsideClickDismisses={true}
          />
          {errorModalShowing && <div id='modal-backdrop'></div>}
          <div className='tab-content profile-tab-content show-scrollbar'>
            <Card
              title='Personal Information'
              theme={props.theme}
              titleStyle={{fontSize: 'var(--font-sizes-medium)'}}
            >
              <Input
                label='Company Name (Locked)'
                value={props.orgDetails?.orgName}
                theme={props.theme}
                disabled
              />
              <div className='gap-group-columns'>
                <Input
                  label='First Name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  theme={props.theme}
                />
                <Input
                  label='Last Name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  theme={props.theme}
                />
              </div>
              {/* {getInput('Email', email, setEmail, {disabled: true})} */}
              <Input
                label='Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled
                theme={props.theme}
              />
              <Input
                onChange={(e) => {
                  setCodingLanguage(e.target.value)
                }}
                label='Choose your preferred coding language'
                placeholder='None'
                value={codingLanguage}
                theme={props.theme}
                required
                type={3}
                options={codingLanguageList}
                iconLeft={<Icon icon='menu-outline' className='profile-left-icon'/>}
              />
            </Card>
            <Card
              title='Password Management'
              theme={props.theme}
              titleStyle={{fontSize: 'var(--font-sizes-medium)'}}
            >
              <form>
                <Input
                  label='Current Password'
                  placeholder='**********'
                  inputType={showCurrentPassword ? 1 : 'password'}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  value={currentPassword}
                  theme={props.theme}
                  iconRight={
                    <Icon
                      icon={showCurrentPassword ? 'eye-off-2-outline' : 'eye-outline'}
                      key={showCurrentPassword ? 'show-current' : 'no-show-current'}
                      onClick={(e) => {
                        setShowCurrentPassword(!showCurrentPassword)
                      }}
                      className='password-change-icon'
                    />
                  }
                />
                <Input
                  label='New Password'
                  placeholder='**********'
                  inputType={showNewPassword1 ? 1 : 'password'}
                  onChange={(e) => setNewPassword1(e.target.value)}
                  value={newPassword1}
                  theme={props.theme}
                  iconRight={
                    <Icon
                      icon={showNewPassword1 ? 'eye-off-2-outline' : 'eye-outline'}
                      key={showNewPassword1 ? 'show-new-1' : 'no-show-new-1'}
                      onClick={(e) => {
                        setShowNewPassword1(!showNewPassword1)
                      }}
                      className='password-change-icon'
                    />
                  }
                />
                <Input
                  label='Repeat New Password'
                  placeholder='**********'
                  inputType={showNewPassword2 ? 1 : 'password'}
                  onChange={(e) => setNewPassword2(e.target.value)}
                  value={newPassword2}
                  theme={props.theme}
                  iconRight={
                    <Icon
                      icon={showNewPassword2 ? 'eye-off-2-outline' : 'eye-outline'}
                      key={showNewPassword2 ? 'show-new-2' : 'no-show-new-2'}
                      onClick={(e) => {
                        setShowNewPassword2(!showNewPassword2)
                      }}
                      className='password-change-icon'
                    />
                  }
                />
                <div
                  className={
                    newPassword1.length === 0 && newPassword2.length === 0 ?
                      'hidden' :
                      ''
                  }
                >
                  <div className='profile-pwd-header'>Password must have:</div>
                  {Object.keys(passwordCheck).map((key, i) => (
                    <div
                      key={i + key}
                      className={`profile-pwd-text flex align-center ${
                        passwordCheck[key] ?
                          'text-success-light' :
                          'text-error-light'
                      }`}
                    >
                      <div
                        className={`toggle-container ${
                          passwordCheck[key] ? 'toggled' : ''
                        }`}
                      >
                        <Icon icon='checkmark-outline' />
                        <Icon icon='close-outline' />
                      </div>
                      {key}
                    </div>
                  ))}
                </div>
              </form>
              {props.showingMFA &&
                mfa
              }
            </Card>
            <Card
              theme={props.theme}
              title='Enable Cookies'

              titleStyle={{fontSize: 'var(--font-sizes-medium)', marginBottom: 'var(--spacing-01)'}}
            >

              <Switch
                label={
                  <>
                    <p>Opt-in and share anonymous data to help us enhance our products.</p>
                    <p>
                      By accepting cookies you agree to the use of cookies to analyze and improve our services.
                      You can learn more about how and why we use cookies in our <a href='https://www.lazarusforms.com/legal#terms-and-conditions' target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}}>Cookie Policy</a>.
                    </p>
                    <p>
                      Accepting cookies will allow you to skip sign in.
                    </p>
                  </>
                }
                checked={analyticsConsent}
                onChange={onSwitchChange}
                theme={props.theme}
              />
            </Card>
            <MFA props={props}/>
          </div>
          {isButtonLower && <div className={`buttons-lower buttons-lower-${isButtonLowerShowing ? 'in' : 'out'}`}>
            <Button
              type='8'
              text='Save'
              onClick={saveChanges}
              theme={props.theme}
              icon={<Icon icon='arrow-forward-outline' />}
              iconPosition='right'
              iconJustify='edge'
            />
            <Button
              type='10'
              text='Revert Changes'
              onClick={revertChanges}
              theme={props.theme}
              icon={<Icon icon='alert-circle-outline' />}
              iconPosition='right'
              iconJustify='edge'
            />
          </div>}
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  database: state.firebaseReducer.database,
  user: state.userReducer.user,
  orgId: state.userReducer.orgId,
  orgDetails: state.userReducer.orgDetails,
  userData: state.userReducer.userData,
  theme: state.userReducer.theme,
  isSaveCred: state.userReducer.isSaveCred,
  profileChangesMade: state.userReducer.profileChangesMade,
  preventNavigationListener: state.userReducer.preventNavigationListener,
  showingMFA: state.userReducer.showingMFA,
  mfaError: state.userReducer.mfaError,
})

export default connect(mapStateToProps, {
  storePayload,
  setDefaultLanguage,
  setNames,
  removeAnalytics,
  initializeAnalytics,
  enterMFACode,
})(Profile)
