import types from '../types'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import {onSignInSuccess} from './onSignInSuccess'

export const initializePassword =
  (username, password, accessCode, database) => (dispatch) => {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          firebase
              .auth()
              .signInWithEmailAndPassword(username, accessCode)
              .then((user) => {
                return user
              })
              .then((user) => {
                user = firebase.auth().currentUser
                const dbRef = database.ref(`users/${user.uid}/accessCodes/`)
                dbRef
                    .get()
                    .then((snapshot) => {
                      const accessCodes = snapshot.val()
                      // check accessCode in accessCodes and dateUsed is still 0
                      if (
                        !accessCodes[accessCode] ||
                  Object.keys(accessCodes[accessCode]).includes('dateUsed')
                      ) {
                        throw new Error('Access Code is not valid.')
                      }
                      // return error is access bad
                      user
                          .updatePassword(password)
                          .then(() => {
                            // password changed
                            // set accessCode to used
                            const newAccessCode = accessCodes[accessCode]
                            newAccessCode['dateUsed'] = Date.now()
                            const dbACRef = database.ref(
                                `users/${user.uid}/accessCodes/${accessCode}`,
                            )
                            return dbACRef
                                .set(newAccessCode)
                                .then(() => {
                                  // set isSignedIn to true
                                  dispatch({
                                    type: types.STORE_PAYLOAD,
                                    payload: {
                                      isSignedIn: true,
                                    },
                                  })
                                  return firebase.auth().currentUser
                                })
                                .catch((error) => {
                                  dispatch({
                                    type: types.STORE_PAYLOAD,
                                    payload: {
                                      isLoading: false,
                                      initializationMessage:
                              'Was not able to disable access code. Contact Lazarus.',
                                    },
                                  })
                                })
                          }, (error) => {
                            console.log('Error: ', error)
                            dispatch({
                              type: types.STORE_PAYLOAD,
                              payload: {
                                isLoading: false,
                                initializationMessage:
                                  'Was not able to set password. Contact Lazarus.',
                              },
                            })
                          })
                          .then((user) => {
                            if (user) {
                              dispatch(onSignInSuccess(user, database))
                            }
                          })
                          .catch((error) => {
                            dispatch({
                              type: types.STORE_PAYLOAD,
                              payload: {
                                isLoading: false,
                                initializationMessage:
                          'Was not able to set password. Contact Lazarus.',
                              },
                            })
                          })
                    })
                    .catch((error) => {
                      dispatch({
                        type: types.STORE_PAYLOAD,
                        payload: {
                          isLoading: false,
                          initializationMessage:
                      'Access code is not valid. Please reach out to Lazarus.',
                        },
                      })
                    })
              })
              .catch((error) => {
                // catch from access code log in
                dispatch({
                  type: types.STORE_PAYLOAD,
                  payload: {
                    isLoading: false,
                    initializationMessage:
                  'Access code is no longer active. Contact the Lazarus employee that sent you the link.',
                  },
                })
              })
        })
  }
