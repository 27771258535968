const text = (orgId, authKey) =>
  `
import requests

url = "https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/forms/identification"

payload={}

files=[
  ('file',('file_name',open('file_HERE','rb'),'application/octet-stream'))
]

headers = {
  'orgId': ${orgId},
  'authKey': ${authKey}
}

response = requests.post(url, headers=headers, data=payload, files=files)

print(response.json())
  `

export default text
