import types from '../types'
import Helpers from '../Helpers.js'
import endpoints from '.././endpoints.json'

const _parseFloat = (value) => {
  return (parseFloat(value) || null)
}

const parseBilling = (billing, modelAccess) => {
  if (billing?.creditLimit && !Object.keys(billing?.creditLimit).length) {
    delete billing.creditLimit
  }
  if (billing?.creditLimit?.creditLimit) {
    billing.creditLimit.creditLimit = _parseFloat(billing?.creditLimit?.creditLimit)
  }
  if (billing?.pageLimit && !Object.keys(billing?.pageLimit).length) {
    delete billing.pageLimit
  }
  if (billing?.pageLimit?.pageLimit) {
    billing.pageLimit.pageLimit = _parseFloat(billing?.pageLimit?.pageLimit)
  }
  const modelIds = Object.keys(billing?.modelLimit || {})
  const modelSpecificLimits = ['pageLimit', 'creditLimit', 'promptLimit']
  for (let mIndex = 0; mIndex < modelIds.length; mIndex++) {
    if (modelAccess.includes(modelIds[mIndex])) {
      for (let lIndex = 0; lIndex < modelSpecificLimits.length; lIndex++) {
        if (billing?.modelLimit?.[modelIds[mIndex]]?.[modelSpecificLimits[lIndex]]) {
          billing.modelLimit[modelIds[mIndex]][modelSpecificLimits[lIndex]] = _parseFloat(billing.modelLimit[modelIds[mIndex]][modelSpecificLimits[lIndex]])
        }
      }
    } else {
      delete billing.modelLimit[modelIds[mIndex]]
    }
  }
  return billing
}

export const setSubOrgLimits = (orgId, authKey, userId, subOrgId, billing, modelAccess) => async (dispatch) => {
  const url = process.env.REACT_APP_URL + endpoints.subOrgsBilling
  const headers = {
    'ORGID': orgId,
    'AUTHKEY': authKey,
    'USERID': userId,
    'suborgid': subOrgId,
  }
  const body = {
    subOrgId: subOrgId,
    billing: parseBilling(billing, modelAccess),
  }
  const resp = await Helpers.fetchPostJson(url, body, headers)
      .then((res) => {
        return res
      })
      .catch((res) => {
        console.log('error res', res)
        return {status: 'FAILURE'}
      })
  const messages = []
  const responses = [resp]
  const actions = ['Billing Update']
  for (let rIndex = 0; rIndex < responses.length; rIndex++) {
    messages.push(`${actions[rIndex]} ${responses[rIndex]?.status === 'SUCCESS' ? 'Successful': `Failed: ${responses[rIndex]?.error ? Helpers.parseErrorMessages(responses[rIndex]?.error) : responses[rIndex]?.message ? Helpers.parseErrorMessages(responses[rIndex]?.message) : ''}`}`)
  }
  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      userMessage: messages.join('\n'),
      notificationIcon: resp?.status === 'SUCCESS' ? 'check': 'warning',
      notificationType: 1,
    },
  })
  return resp?.status === 'SUCCESS'
}
