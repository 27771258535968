/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import {verifyPasswordResetCode, confirmPasswordReset} from 'firebase/auth'
import {signIn} from '../actions/signIn.js'
import {Navigate} from 'react-router-dom'
import {useSearchParams} from 'react-router-dom'
import {getAuth} from 'firebase/auth'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload.js'
import {
  Icon,
  Input,
  Card,
  Modal2,
  Button,
} from '@lazarusai/forms-ui-components'
import '../styles/ForgotPassword.css'

function ForgotPassword(props) {
  const [searchParams] = useSearchParams()
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [auth, setAuth] = useState()
  const [actionCode, setActionCode] = useState()
  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(true)
  const [email, setEmail] = useState('')
  const [newPassword1, setNewPassword1] = useState('')
  const [showNewPassword1, setShowNewPassword1] = useState(false)
  const [newPassword2, setNewPassword2] = useState('')
  const [showNewPassword2, setShowNewPassword2] = useState(false)
  const [passwordCheck, setPasswordCheck] = useState({
    '8 characters minimum': false,
    'Includes upper and lower case': false,
    'Includes special character': false,
    'Passwords must match': false,
  })

  useEffect(() => {
    if (props.database && Object.keys(props.database).length > 0) {
      const newAuth = getAuth()
      setAuth(newAuth)
      const newActionCode = searchParams.get('oobCode')
      setActionCode(newActionCode)
      handleResetPassword(newAuth, newActionCode)
    }
  }, [props.database])

  useEffect(() => {
    const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
    setPasswordCheck({
      '8 characters minimum': newPassword1.length >= 8,
      'Includes upper and lower case':
        newPassword1.toUpperCase() !== newPassword1 &&
        newPassword1.toLowerCase() !== newPassword1,
      'Includes special character': specialCharRegex.test(newPassword1),
      'Passwords must match': newPassword1 && newPassword2 && newPassword1 === newPassword2,
    })
  }, [newPassword1, newPassword2])

  function handleResetPassword(auth, actionCode, continueUrl = '', lang = 'en') {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, actionCode).then((email) => {
      setEmail(email)
      props.storePayload({isLoading: false})

      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.

      // Save the new password.
    }).catch((error) => {
      setShouldRedirect(true)
      console.log('Error: ', error)
      props.storePayload({
        userMessage: 'It seems your password-reset link might have expired. Please click "Forgot password" again.',
        notificationIcon: 'warning',
        notificationType: 3,
      })
    }).finally(() => {
      props.storePayload({isLoading: false})
    })
  }

  function onClickNext() {
    props.storePayload({isLoading: true})
    confirmPasswordReset(auth, actionCode, newPassword1)
        .then((resp) => {
          // Password reset has been confirmed and new password updated.

          // TODO: Display a link back to the app, or sign-in the user directly
          // if the page belongs to the same domain as the app:
          // auth.signInWithEmailAndPassword(accountEmail, newPassword);

          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
          props.signIn(
              email, newPassword1, false, props.database,
          )
          props.storePayload({
            userMessage: 'Password successfully changed.',
            notificationIcon: 'check',
            notificationType: 1,
          })
          setShouldRedirect(true)
        }).catch((error) => {
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
          console.log('error: ', error)
          props.storePayload({
            userMessage: 'There was an error reseting your password',
            notificationIcon: 'warning',
            notificationType: 2,
          })
        })
        .finally(() => {
          props.storePayload({isLoading: false})
        })
  }

  const isPasswordValid =
    Object.keys(passwordCheck).every((rule) => {
      return (
        passwordCheck[rule] === true
      )
    })

  const footer = (
    <footer>
      <Button
        text='Next'
        onClick={() => {
          onClickNext()
        }}
        theme={props.theme}
        disabled={!isPasswordValid}
      />
    </footer>
  )

  function dismissModal() {
    setIsForgotPasswordModalVisible(false)
  }

  if (props.isSignedIn || shouldRedirect) {
    return <Navigate to='/' />
  }

  return (
    <div className='password-reset-container'>
      <Modal2
        title='Reset Password'
        isVisible={isForgotPasswordModalVisible}
        isDismissable={false}
        showBackdrop={true}
        footer={footer}
        onDismiss={dismissModal}
        style={{height: '408px'}}
      >
        <div>
          <form>
            <Input
              label='New Password'
              placeholder='**********'
              inputType={showNewPassword1 ? 1 : 'password'}
              onChange={(e) => setNewPassword1(e.target.value)}
              value={newPassword1}
              theme={props.theme}
              iconRight={
                <Icon
                  icon={showNewPassword1 ? 'eye-off-2-outline' : 'eye-outline'}
                  key={showNewPassword1 ? 'show-new-1' : 'no-show-new-1'}
                  onClick={(e) => {
                    setShowNewPassword1(!showNewPassword1)
                  }}
                  className='password-change-icon'
                />
              }
            />
            <Input
              label='Repeat New Password'
              placeholder='**********'
              inputType={showNewPassword2 ? 1 : 'password'}
              onChange={(e) => setNewPassword2(e.target.value)}
              value={newPassword2}
              theme={props.theme}
              iconRight={
                <Icon
                  icon={showNewPassword2 ? 'eye-off-2-outline' : 'eye-outline'}
                  key={showNewPassword2 ? 'show-new-2' : 'no-show-new-2'}
                  onClick={(e) => {
                    setShowNewPassword2(!showNewPassword2)
                  }}
                  className='password-change-icon'
                />
              }
            />
            <div
              className={
                newPassword1.length === 0 && newPassword2.length === 0 ?
                  'hidden' :
                  ''
              }
            >
              <div className='profile-pwd-header'>Password must have:</div>
              {Object.keys(passwordCheck).map((key, i) => (
                <div
                  key={i + key}
                  className={`profile-pwd-text flex align-center ${
                    passwordCheck[key] ?
                      'text-success-light' :
                      'text-error-light'
                  }`}
                >
                  <div
                    className={`toggle-container ${
                      passwordCheck[key] ? 'toggled' : ''
                    }`}
                  >
                    <Icon icon='checkmark-outline' />
                    <Icon icon='close-outline' />
                  </div>
                  {key}
                </div>
              ))}
            </div>
          </form>
        </div>
      </Modal2>
    </div>
  )
}

const mapStateToProps = (state) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  theme: state.userReducer.theme,
})

export default connect(mapStateToProps, {
  storePayload, signIn,
})(ForgotPassword)
