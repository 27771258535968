import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
// import codingLanguageList from '../CodingLanguages.js'
// import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import {
  Icon,
  Table,
  TableEntry,
  // Button,
  // Input,
  SearchBar,
} from '@lazarusai/forms-ui-components'
import {storePayload} from '../actions/storePayload.js'

import '../styles/Roles.css'
import Helpers from '../Helpers.js'

const UserTable = (props) => {
  const [sortField, setSortField] = useState('email');
  const [sortDescend, setSortDescend] = useState(true);
  const [selectSort, setSelectSort] = useState([]);
  const [tableEntries, setTableEntries] = useState([]);
  const [userSearch, setUserSearch] = useState('');

  // Update userTableData and sorted data when props.usersObj changes
  useEffect(() => {
    if (props.usersObj) {
      const userTableDataArray = Object.keys(props.usersObj).map((key) => ({
        key,
        ...props.usersObj[key],
      }));
      const userTableData = userTableDataArray.filter((user) => user.role === props.roleSelection);
      let filteredUsers = [...userTableData];
      if (userSearch) {
        filteredUsers = filteredUsers.filter((user) => user?.email?.includes(userSearch));
      }
      if (props.roleSelection) {
        filteredUsers = filteredUsers.filter((user) => user.role === props.roleSelection);
      }
      setSelectSort(sortUsers(filteredUsers));
    }
  }, [props.usersObj, props.roleSelection, userSearch, sortDescend, sortField]);

  function sortUsers(userTableDataArray) {
    if (sortField === 'email') {
      return userTableDataArray.slice().sort((a, b) => (sortDescend ? 1: -1) * a.email.localeCompare(b.email))
    } else {
      return userTableDataArray.sort((a, b) => (sortDescend ? 1: -1) * (new Date(a.datetimeCreated) - new Date(b.datetimeCreated)))
    }
  }

  // Update table entries when selectSort changes
  useEffect(() => {
    if (selectSort) {
      setTableEntries(
          selectSort.map((user, i) => (
            <TableEntry
              key={`${user.key}-id-${i}`}
              content={[
                <p key='email' className='light-blue'>{user.email}</p>,
                <p key='date' className='dark-gray'>{user.datetimeCreated}</p>,
                <p key='role' className='text-bright-green'>{capitalize(user.role)}</p>,
              ]}
            />
          )),
      );
    }
  }, [selectSort]);

  // Capitalize string utility function
  const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const search = (
    <>
      <br></br>
      <div className='single-line-padding'>
        <SearchBar
          value={userSearch}
          onChange={(e) => setUserSearch(e.target.value)}
          theme={props.theme}
          placeHolder='Search'
        />
      </div>
      <br></br>
    </>
  )

  function sortToggle(sortKey) {
    if (sortKey === sortField) {
      setSortDescend(!sortDescend)
    } else {
      setSortField(sortKey)
      setSortDescend(true)
    }
  }

  const table = (
    <>
      <Table
        theme={props.theme}
        label={userSearch}
        title={Helpers.capitalizeFirstLetter(props.roleSelection)}
        columnTitles={[
          <span
            key='email'
            onClick={() => sortToggle('email')}
            style={{paddingLeft: '4px', cursor: 'pointer'}}
            className='table-header-div'
          >Email{(sortField === 'email') &&
            <Icon
              icon={sortDescend ? 'arrow-downward-outline' : 'arrow-upward-outline'}
              key={`email-arrrow-${sortDescend ? 'downward' : 'upward'}`}
            />
            }</span>,
          <span
            key='date'
            onClick={() => sortToggle('date')}
            style={{paddingLeft: '4px', cursor: 'pointer'}}
            className='table-header-div'
          >Date{(sortField === 'date') &&
            <Icon
              icon={sortDescend ? 'arrow-downward-outline' : 'arrow-upward-outline'}
              key={`date-arrrow-${sortDescend ? 'downward' : 'upward'}`}
            />
            }</span>,
          <span
            key='usergroup'
            style={{paddingLeft: '4px'}}
            className='table-header-div'
          >User Group</span>,
        ]}
        showCount={true}
        useFilter={false}
        entries={tableEntries}
        maxHeight='50rem'
      />
    </>
  );

  return (
    <div>
      {search}
      {table}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  cookies: ownProps.cookies,
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  usersObj: state.userReducer.usersObj,
  users: state.userReducer.users,
  userData: state.userReducer.userData,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  orgId: state.userReducer.orgId,
  subOrgs: state.userReducer.subOrgs,
  orgActionLogs: state.userReducer.orgActionLogs,
  customerPortal: state.userReducer.customerPortal,
  billing: state.userReducer.billing,
  theme: state.userReducer.theme,
  analytics: state.firebaseReducer.analytics,
  profileChangesMade: state.userReducer.profileChangesMade,
  preventNavigationListener: state.userReducer.preventNavigationListener,
  vkgDomain: state.userReducer.vkgDomain,
})

export default connect(mapStateToProps, {
  storePayload,
})(UserTable)
