import types from '../types'
import Helpers from '../Helpers.js'
import 'firebase/compat/auth'
import {signIn} from './signIn'
// import {logAnalyticsEvent} from './logAnalyticsEvent'
import endpoints from '.././endpoints.json'

export const signUp = (authForm, database, resetModal) => (dispatch) => {
  const {signUpUsername, signUpPassword, orgName, firstName, lastName, cookiesEnabled} = authForm
  if (!signUpPassword || !signUpUsername || !orgName) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        userMessage: 'Please complete the form',
        notificationIcon: 'warning',
        notificationType: 3,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    const data = {
      org_name: orgName,
      user_email: signUpUsername.toLowerCase(),
      user_password: signUpPassword,
      first_name: firstName,
      last_name: lastName,
      consentMode: {'ad_storage': 'denied', 'analytics_storage': cookiesEnabled ? 'granted': 'denied'},
    }

    // const url = process.env.REACT_APP_URL + 'account/signup'
    const url = process.env.REACT_APP_URL + endpoints.signup
    Helpers.fetchPostJson(url, data)
        .then((res) => {
          if (res.error) {
            signUpFailureCB(res)
          } else {
            if (resetModal) {
              resetModal()
            }
            dispatch(signIn(signUpUsername, signUpPassword, true, database))
          }
        })
        .catch(signUpFailureCB)
  }

  function signUpFailureCB(response) {
    /*
      Sometimes response.error is an object and sometimes it
      is a string. Until this is fixed on backend, we check
      if the error is a string. When it is a string, it has a more
      specific user message that we want to show th user.
    */
    const messageToShow =
      typeof response.error === 'string' ? response.error : response.message
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        userMessage: messageToShow,
        notificationType: 3,
        notificationIcon: 'warning',
        userData: null,
      },
    })
    // const signUpFailureEvent = {
    //   email: username,
    //   organization: organization,
    //   page: window.location.pathname.substring(1),
    //   time: Date.now(),
    //   user: window.navigator.userAgent,
    //   event_type: 'api',
    // }
    // logAnalyticsEvent(
    //     'sign_up_failure',
    //     signUpFailureEvent,
    // )
  }
}
