import types from '../types'
import Helpers from '../Helpers.js'
import endpoints from '.././endpoints.json'

export const createSubOrganization = (orgId, authKey, userId, body) => async (dispatch) => {
  const url = process.env.REACT_APP_URL + endpoints.subOrgs
  const headers = {
    'ORGID': orgId,
    'AUTHKEY': authKey,
    'USERID': userId,
    'parentOrgId': body['parentOrgId'],
  }

  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      userMessage: `Creating suborganization...`,
      notificationIcon: 'check',
      notificationType: 1,
    },
  })

  return await Helpers.fetchPostJson(url, body, headers)
      .then((res) => {
        if (res?.status === 'SUCCESS') {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: `Successfully created suborganization${res.message ? `: \n${Helpers.parseErrorMessages(res.message).join('\n\n')}`: '.'}`,
              notificationIcon: 'check',
              notificationType: res.message ? 1 : 2,
            },
          })
          return true
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: `Error creating suborganization${res.message ? `: \n${Helpers.parseErrorMessages(res.message).join('\n\n')}`: res.error ? `: ${Helpers.parseErrorMessages(res.error).join('\n')}`: '.'}`,
              notificationIcon: 'warning',
              notificationType: 3,
            },
          })
          return false
        }
      })
      .catch((res) => {
        console.log('error res', res)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Error creating suborganization.',
            notificationIcon: 'warning',
            notificationType: 3,
          },
        })
        return false
      })
}
